<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        style="display: block;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Montserrat, sans-serif;"
      >
        <div style="width: 100%;color: #fff;" :style="{background:resume.profile.color}">
          <div class="top-section" style="display:flex;justify-content:space-between;">
            <div style="padding: 20px;">
              <h1
                v-if="(resume.profile.firstname)"
                style="font-size: 36px; margin:0;word-wrap:break-word;"
              >{{resume.profile.firstname +" "+resume.profile.surname}}</h1>
              <h1
                v-if="resume.currentstep!=17 && !(resume.profile.firstname)"
                style="font-size: 36px; margin:0;"
              >Suresh Raina</h1>
              <span
                v-if="(resume.profile.profession)"
                style="display: block;opacity: 0.8;margin: 5px 0 0;"
              >{{resume.profile.profession}}</span>
              <span
                v-if="resume.currentstep!=17 && !(resume.profile.profession)"
                style="display: block;opacity: 0.8;margin: 5px 0 0;"
              >Profession</span>

              <p v-if="(resume.profile.email)" style="margin:0;">
                <b style="font-weight: 600;">Email:</b>
                {{resume.profile.email}}
              </p>
              <p v-if="resume.currentstep!=17 && !(resume.profile.email)" style="margin:0;">
                <b style="font-weight: 600;">Email:</b>
                suresh@email.com
              </p>
              <p v-if="(resume.profile.phone)" style="margin:0;">
                <b style="font-weight: 600;">Phone:</b>
                {{resume.profile.phone}}
              </p>
              <p v-if="resume.currentstep!=17 && !(resume.profile.phone)" style="margin:0;">
                <b style="font-weight: 600;">Phone:</b>
                Your Mobile Number
              </p>
              <p v-if="(resume.profile.address)" style="margin:0;">
                <b style="font-weight: 600;">Address:</b>
                {{resume.profile.address}}
              </p>
              <p v-if="resume.currentstep!=17 && !(resume.profile.address)" style="margin:0;">
                <b style="font-weight: 600;">Address:</b>
                Your Address
              </p>
              <p v-if="resume.profile.linkedin" style="margin:0;">
                <b style="font-weight: 600;">LinkedIn:</b>
                {{resume.profile.linkedin}}
              </p>
              <p v-if="resume.profile.website" style="margin:0;">
                <b style="font-weight: 600;">Website:</b>
                {{resume.profile.website}}
              </p>
              <p v-if="resume.profile.twitter" style="margin:0;">
                <b style="font-weight: 600;">Twitter:</b>
                {{resume.profile.twitter}}
              </p>
            </div>
            <div class="img-box" style="padding: 20px 20px 0;">
              <img
                v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                style="max-width: 150px;width: 100%;object-fit: cover;"
                :src="resume.pic"
                alt
              >
              <img
                v-else
                style="max-width: 150px;width: 100%;object-fit: cover;"
                src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                alt
              >
            </div>
          </div>
        </div>

        <div style="width: 100%;padding: 20px 30px; box-sizing:border-box;">
          <p
            v-if="resume.summary"
            v-html="resume.summary"
            class="big-text"
            style="font-size: 18px;line-height: 1.3;margin:0;"
          ></p>
          <p
            v-if="resume.currentstep!=17 && !(resume.summary)"
            class="big-text"
            style="font-size: 18px;   line-height: 1.3;    margin: 0;   color: gray;"
          >Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.</p>
          <div
            v-if="resume.skills && resume.skills.length>0 "
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;" 
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3
                style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}"
              >Technical Profile</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li v-for="(item,index) in resume.skills" style="padding-bottom: 10px;">
                    {{item.text}}
                    <div style="display:block;">
                      <span
                        v-for="(data,index) in item.rate"
                        style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                      ></span>
                      <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                      ></span>-->
                      <span
                        v-if="(5-item.rate)!=5"
                        v-for="(data,index) in (5-item.rate)"
                        style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                      ></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.currentstep!=17 && !(resume.skills && resume.skills.length>0) "
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left"
              :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3
                style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}"
              >Technical Profile</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li style="padding-bottom: 10px; color:grey;">
                    Accurate money handling
                  </li>
                  <li style="padding-bottom: 10px; color:grey;">
                    Sales Enterprising
                  </li>
                  <li style="padding-bottom: 10px; color:grey;">
                    Store opening and closing
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div
            v-if="resume.workExperience&&resume.workExperience.length>0"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Work History</h3>
            </div>
            <div class="resume-box">
              <div
                v-for="(item,index) in resume.workExperience"
                style="display: flex;margin-bottom: 25px;"
              >
                <span
                  style="font-weight: 600;display: block;margin: 5px 30px 0 0; max-width:87px; width:100%;"
                >{{item.startdate}} - {{item.enddate}}</span>
                <div style="max-width: calc(100% - 120px);">
                  <h2 style="font-size: 24px;font-weight: 600;margin: 0 0 5px;">{{item.jobtitle}}</h2>
                  <span
                    style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;"
                  >{{item.employer}} {{item.city +" " + item.country}}</span>
                  <ul style="list-style: disc;padding-left: 15px; margin:0;">
                    <li style="padding-bottom: 10px;" v-html="item.description"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left"
              :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Work History</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <span
                  style="font-weight: 600;display: block;margin: 5px 30px 0 0; color:grey;"
                >07/2017 - Current</span>
                <div>
                  <h2 style="font-size: 24px;font-weight: 600;margin: 0 0 5px; color:grey;">Software Engineer</h2>
                  <span
                    style="font-style: italic;display: block; color:grey; margin: 5px 30px 10px 0; font-weight:600;"
                  >Compubotics Banglore,India</span>
                  <ul style="list-style: disc;padding-left: 15px; margin:0;">
                    <li style="padding-bottom: 10px; color:grey;" >Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.</li>
                    <li style="padding-bottom: 10px; color:grey;" >Used consultative sales approach to understand customer needs and recommend relevant offerings.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="resume.education&&resume.education.length>0"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Education</h3>
            </div>
            <div class="resume-box">
              <div
                v-for="(item,index) in resume.education"
                style="display: flex;margin-bottom: 25px;"
              >
                <span
                  style="font-weight: 600;display: block;margin: 5px 30px 0 0; max-width:87px; width:100%;"
                >{{item.grad_startdate }} - {{item.grad_enddate}}</span>
                <div style="max-width: calc(100% - 120px);">
                  <h2
                    style="font-size: 24px;font-weight: 600;margin: 0 0 5px;"
                  >{{item.degree +" "+item.field }}</h2>
                  <span
                    style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;"
                  >{{ item.school_name }} - {{ item.school_location }}</span>
                  <ul style="list-style: disc;padding-left: 15px; margin:0;">
                    <li style="padding-bottom: 10px;" v-html="item.description"></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;" :style="{background:resume.profile.color}"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Education</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <span
                  style="font-weight: 600;display: block;margin: 5px 30px 0 0;color:grey;"
                >01/2013 - 03/2016</span>
                <div>
                  <h2
                    style="font-size: 24px;font-weight: 600;margin: 0 0 5px;color:grey;"
                  >Msc - Computer Science</h2>
                  <span
                    style="font-style: italic;display: block;margin: 5px 30px 10px 0; font-weight:600;color:grey;"
                  >Oxford University - Mumbai,India</span>
                  <ul style="list-style: disc;padding-left: 15px; margin:0;color:grey;">
                    <li style="padding-bottom: 10px;">Dean's list for the 2015–2016 academic year</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="resume.extraFields.accomplishments_text!='None'"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Accomplishments</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li
                    v-html="resume.extraFields.accomplishments_text"
                    style="padding-bottom: 10px;"
                  ></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.affiliations_text!='None'"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 :style="{color:resume.profile.color}" style="font-size: 26px;font-weight: 700;margin:0;">Affiliation</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li v-html="resume.extraFields.affiliations_text" style="padding-bottom: 10px;"></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.additional_information_text!='None'"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3
                style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}"
              >Additional Information</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li
                    v-html="resume.extraFields.additional_information_text"
                    style="padding-bottom: 10px;"
                  ></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.software_text!='None'"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Software</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li v-html="resume.extraFields.software_text" style="padding-bottom: 10px;"></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.languages_text!='None'"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Language</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li v-html="resume.extraFields.languages_text" style="padding-bottom: 10px;"></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.certifications_text!='None'"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 :style="{color:resume.profile.color}" style="font-size: 26px;font-weight: 700;margin:0;">Certification</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li v-html="resume.extraFields.certifications_text" style="padding-bottom: 10px;"></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.interests_text!='None'"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">Interests</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <ul style="list-style: disc;padding-left: 15px; margin:0;">
                  <li v-html="resume.extraFields.interests_text" style="padding-bottom: 10px;"></li>
                </ul>
              </div>
            </div>
          </div>
          <div
            v-if="resume.extraFields.add_your_own_json.length>0"
            v-for="(data,index) in resume.extraFields.add_your_own_json"
            style="position:relative; padding-left: 60px; margin-top: 10px; padding-top:25px;"
          >
            <div
              class="border-left" :style="{background:resume.profile.color}"
              style="position: absolute;width: 1px;height: 100%;left: 19px;top: 0;"
            ></div>
            <div class="heading-icon" style="position:absolute; left:0; width: 40px;height: 40px;">
              <span :style="{background:resume.profile.color}"
                style="transform: rotate(-45deg);display: inline-block;width: 40px;height: 40px;text-align: center;"
              ></span>
              <img
                style="position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"
                width="24"
                height="24"
                src="https://img.icons8.com/ios/24/ffffff/contract-job.png"
                alt="contract-job"
              >
            </div>
            <div
              class="heading"
              style="font-size: 26px;font-weight: 700;padding: 0;margin:0 0 25px; position:relative; top:4px;"
            >
              <h3 style="font-size: 26px;font-weight: 700;margin:0;" :style="{color:resume.profile.color}">{{data.title}}</h3>
            </div>
            <div class="resume-box">
              <div style="display: flex;margin-bottom: 25px;">
                <p style="margin:0;" v-html="data.body"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false,
      onclicksave:false
    };
  },
  computed: {},
  methods: {
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Color:self.resume.color,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        //url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            setTimeout(y => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    }
  }
};
</script>


