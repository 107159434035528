<template>
<div>
  <div id="resumesec">
    <body style="margin:0; padding:0;">
      <div
        style="display: flex;height: 100%; font-size: 16px; color: #000; line-height: 1.3;font-family:Poppins, sans-serif;"
      >
        <div
          style="width: 30%;color: #000; background:none; padding:30px 15px;"
        >
          <div class="img-box" style="padding: 20px 20px 0; text-align:center">
              <img
                v-if="resume.pic&&resume.pic!=''&&resume.pic!=undefined"
                style="max-width: 150px;width: 100%;object-fit: cover;"
                :src="resume.pic"
                alt
              >
              <img
                v-else
                style="max-width: 150px;width: 100%;object-fit: cover;"
                src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                alt
              >
            </div>
          <div style="text-align:center;">
            <h1 v-if="resume.profile.firstname"
              style="display:block; text-transform:uppercase; word-wrap:break-word; font-size: 36px;font-weight: 700; margin:0; text-align:center;"
            >
              {{resume.profile.firstname}}
              <br>{{resume.profile.surname}}
            </h1>
            <h1 v-if="resume.currentstep!=17 && !resume.profile.firstname"
              style="display:block; text-transform:uppercase;word-wrap:break-word; font-size: 36px;font-weight: 700; margin:0; text-align:center;"
            >
              Suresh
              <br>Raina
            </h1>
            <span
              style="display:block; text-transform:uppercase;  font-size: 14px;font-weight:500; letter-spacing:1px; margin-bottom:30px;"
            >{{resume.profile.profession}}</span>
          </div>
          <div style>
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:0 30px 30px 0;"
            >Contact</div>
            <div style="padding:20px 10px; font-size: 15px;">
              <div v-if="resume.profile.address" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/0097b2/home--v1.png"
                    alt="home--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.address}}</p>
              </div>
              <div v-if="resume.profile.phone" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/0097b2/phone-disconnected.png"
                    alt="phone-disconnected"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.phone}}</p>
              </div>
              <div v-if="resume.profile.linkedin" style="display:flex; margin-bottom:15px; ">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/color/36/linkedin.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.linkedin}}</p>
              </div>
              <div v-if="resume.profile.website" style="display:flex; margin-bottom:15px; ">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/glyph-neue/24/0097b2/globe--v1.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.website}}</p>
              </div>
              <div v-if="resume.profile.twitter" style="display:flex; margin-bottom:15px; ">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/glyph-neue/36/5B94DE/twitter--v1.png"
                    alt="globe--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{resume.profile.twitter}}</p>
              </div>

              <div v-if="resume.profile.email" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/0097b2/new-post--v1.png"
                    alt="new-post--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;">{{ resume.profile.email }}</p>
              </div>
              <div v-if="resume.currentstep!=17 && !resume.profile.email" style="display:flex; margin-bottom:15px;">
                <span style=" width:24px; height:24px;min-width:24px;">
                  <img
                    width="18"
                    height="18"
                    src="https://img.icons8.com/ios/24/0097b2/new-post--v1.png"
                    alt="new-post--v1"
                  >
                </span>
                <p style="margin:0 0 0 5px;color:grey;">suresh@email.com</p>
              </div>
            </div>
          </div>

          <div v-if="resume.skills.length>0" style>
            <div
            v-if="resume.skills.length>0" :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:0 30px 30px 0;"
            >Skills</div>
            <div style="padding:20px 10px; font-size: 15px;">
              <div v-for="(item,index) in resume.skills" style="margin-bottom:15px;">
                <p style="margin:0 0 5px; font-weight:500;">{{item.text}}</p>
                <div style="display:block;">
                  <span
                  v-for="(data,index) in item.rate"
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <!-- <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span>
                  <span
                    style="width:10px; height:10px; border-radius:50%; background:#999; display:inline-block; margin-right:4px;"
                  ></span> -->
                  <span v-if="(5-item.rate)!=5"
                  v-for="(data,index) in (5-item.rate)"
                    style="width:10px; height:10px; border-radius:50%; background:#ccc; display:inline-block; margin-right:4px;"
                  ></span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.skills.length>0" style>
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:0 30px 30px 0;"
            >Skills</div>
            <div style="padding:20px 10px; font-size: 15px;">
              <div style="margin-bottom:15px;">
                <p style="margin:0 0 5px; font-weight:500;color:grey;">Accurate money handling</p>
                <p style="margin:0 0 5px; font-weight:500;color:grey;">Sales Enterprising</p>
                <p style="margin:0 0 5px; font-weight:500;color:grey;">Store opening and closing</p>
              </div>
            </div>
          </div>
        </div>

        <div  style="width: 70%;padding: 30px;">
          <div v-if="resume.summary" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >About Me</div>
            <div>
              <p v-html="resume.summary" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
          <div v-if="resume.currentstep!=17 && !resume.summary" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >About Me</div>
            <div>
              <p  style="margin:10px 0; font-size:16px;color:grey;"> Motivated Sales Associate with 5 years of experience boosting sales and customer loyalty through individualized service. Resourceful expert at learning customer needs, directing to desirable merchandise and upselling to meet sales quotas. Committed to strengthening customer experiences with positivity and professionalism when answering requests and processing sales.
              </p>
            </div>
          </div>
          <div v-if="resume.education&&resume.education.length>0" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Education</div>
            <div v-for="(item,index) in resume.education" style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4
                  style="margin:0 0 5px; font-size:18px; font-weight:500;"
                >{{item.degree +" "+item.field }}</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">{{item.grad_startdate }} - {{item.grad_enddate}}</h5>
                <h6
                  style="margin:0; font-size:15px; font-weight:500; font-style:italic;"
                >{{ item.school_name }} - {{ item.school_location }}</h6>
              </div>
            </div>
            <!-- <div style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;">BA in Product Design</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">2005 - 2007</h5>
                <h6
                  style="margin:0; font-size:15px; font-weight:500; font-style:italic;"
                >Reality great university</h6>
              </div>
            </div> -->
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.education&&resume.education.length>0)" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Education</div>
            <div  style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4
                  style="margin:0 0 5px; font-size:18px; font-weight:500;color:grey;"
                >Msc Computer Science</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;color:grey;">01/2013 - 03/2016</h5>
                <h6
                  style="margin:0; font-size:15px; font-weight:500; font-style:italic;color:grey;"
                >Oxford University - Mumbai,India</h6>
              </div>
            </div>
            <!-- <div style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;">BA in Product Design</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">2005 - 2007</h5>
                <h6
                  style="margin:0; font-size:15px; font-weight:500; font-style:italic;"
                >Reality great university</h6>
              </div>
            </div> -->
          </div>
          <div v-if="resume.workExperience&&resume.workExperience.length>0" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Work Experience</div>
            <div v-if="resume.workExperience&&resume.workExperience.length>0" v-for="(item,index) in resume.workExperience" style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;">{{item.employer}}</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">{{item.startdate}} - {{item.enddate}}</h5>
                <h6 style="margin:0; font-size:14px; font-weight:500;">{{item.jobtitle}}</h6>
                <ul style="margin:0; padding:0 0 0 15px;">
                  <li v-html="item.description" style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    
                  </li>
                  
                </ul>
              </div>
            </div>
            <!-- <div style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;">Reality Great Company</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">2018 - 2020</h5>
                <h6 style="margin:0; font-size:14px; font-weight:500;">Senior Graphics Designer</h6>
                <ul style="margin:0; padding:0 0 0 15px;">
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                </ul>
              </div>
            </div>
            <div style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;">Reality Great Company</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">2018 - 2020</h5>
                <h6 style="margin:0; font-size:14px; font-weight:500;">Senior Graphics Designer</h6>
                <ul style="margin:0; padding:0 0 0 15px;">
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
          <div v-if="resume.currentstep!=17 && !(resume.workExperience&&resume.workExperience.length>0)" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Work Experience</div>
            <div  style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;color:grey;">Compubotics</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;color:grey;">07/2017 - Current</h5>
                <h6 style="margin:0; font-size:14px; font-weight:500;color:grey;">Software Engineer</h6>
                <ul style="margin:0; padding:0 0 0 15px;">
                  <li  style="list-style:disc outside; font-size:14px;color:grey; margin-top:10px;">
                    Effectively upsold products by introducing accessories and other add-ons, adding ₹3000 to average monthly sales.
                  </li>
                  
                </ul>
              </div>
            </div>
            <!-- <div style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;">Reality Great Company</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">2018 - 2020</h5>
                <h6 style="margin:0; font-size:14px; font-weight:500;">Senior Graphics Designer</h6>
                <ul style="margin:0; padding:0 0 0 15px;">
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                </ul>
              </div>
            </div>
            <div style="display:flex; margin:20px 0;">
              <div style="background:#000; width:2px; position:relative;">
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; top:0; left:-4px;"
                ></span>
                <span
                  style="background:#000; width:10px; height:10px; border-radius:50%; position:absolute; bottom:0; left:-4px;"
                ></span>
              </div>
              <div style="padding-left:25px;">
                <h4 style="margin:0 0 5px; font-size:18px; font-weight:500;">Reality Great Company</h4>
                <h5 style="margin:0 0 5px; font-size:15px;font-weight:500;">2018 - 2020</h5>
                <h6 style="margin:0; font-size:14px; font-weight:500;">Senior Graphics Designer</h6>
                <ul style="margin:0; padding:0 0 0 15px;">
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                  <li style="list-style:disc outside; font-size:14px; margin-top:10px;">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec quis velit scelerisque, lobortis libero vitae,
                    efficitur quam. Aenean eu varius lacus.
                  </li>
                </ul>
              </div>
            </div> -->
          </div>
          <div v-if="resume.extraFields.accomplishments_text!='None'" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Accomplishments </div>
            <div>
              <p v-html="resume.extraFields.accomplishments_text" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.affiliations_text!='None'" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Affiliations </div>
            <div>
              <p v-html="resume.extraFields.affiliations_text" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.software_text!='None'" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Software </div>
            <div>
              <p v-html="resume.extraFields.software_text" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.languages_text!='None'" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Languages </div>
            <div>
              <p v-html="resume.extraFields.languages_text" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.certifications_text!='None'" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Certification </div>
            <div>
              <p v-html="resume.extraFields.certifications_text" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.interests_text!='None'" style="margin-bottom:30px;">
            <div
              style="background:#0097b2; color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >Interests </div>
            <div>
              <p v-html="resume.extraFields.interests_text" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
          <div v-if="resume.extraFields.add_your_own_json.length>0" v-for="(data,index) in resume.extraFields.add_your_own_json" style="margin-bottom:30px;">
            <div :style="{background:resume.profile.color}"
              style=" color:#fff; padding:10px 20px; font-size:18px; border-radius:30px 0 0 30px;"
            >{{data.title}} </div>
            <div>
              <p v-html="data.body" style="margin:10px 0; font-size:16px;">
              </p>
            </div>
          </div>
        </div>
      </div>
    </body>
  </div>
  <div v-if="loader">
    <div class="processing_popup upload full-spinner" style="visibility: visible">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <Messages
      v-if="isdisplaymsg"
      :datamessage="sucessmsgtext"
      v-on:closemsg="closeMessage"
      v-bind:isVisible="isdisplaymsg"
    ></Messages>
  </transition>
</div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false
    };
  },
  computed: {},
  methods: {
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId
        });
      } else {
        this.$router.push({
          path: "/dashboard"
        });
      }
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        Color:self.resume.color,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: self.resume.Template
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        //url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            setTimeout(y => {
              self.navigate();
            }, 1000);
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout(xy => {
              self.closeMessage();
            }, 5000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    }
  }
};
</script>


<style scoped>
.ResumeFullPreview #resumesec h1 {
    font-size: 24px !important;
    line-height:1.3 !IMPORTANT;
}
</style>