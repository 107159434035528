<template>
<div>
  <Header :heading="'CV-JD Match'"></Header>
  <div class="main-section" v-if="currentstep==1">
    <Leftmenu></Leftmenu>
    <div class="right-section">
      <div class="resume-builder-main"> 
      

        <div class="jd-job-desc enter-jd-job"> 
        <div class="job-desc">
            <h4>Enter Job Description<span class="required">*</span></h4>
            <textarea v-model="Job_desc"></textarea>
            <span v-if="err_jd" style="
                      color: red;
                      margin-top: 0px;
                      margin-bottom: 7px;
                      display: block;
                    ">Enter valid job description</span>
          </div>
        <div class="btn-sec">
            <a href="#" @click="CVjdMatch" style="float:right;" class="btn-primary md-ink-ripple">Calculate Match</a>
          </div>
          </div>
        </div>   
      
   
  </div>
  
  
  
  
  
  </div>
  <div  v-if="currentstep==2">
    
    <div class="main-section">
    <div class="resume-builder-main">
        <div class="resume-builder-right resume-builder-choose">
          <div class="content profile-match">
            <p>Percentage match: {{match_percentage}}<span v-if="perfectmatch">%</span></p>
            <div class="resume-build">
              <br>
              <p>Reason: {{Reason}}</p>
            </div>
          </div>

          <div :style="{'justify-content:flex-end;':allow_custom_cv}" class="btn-sec">
            <a @click="Back" class="btn-secondary md-ink-ripple">Back</a>
            <a
              v-if="allow_custom_cv"
              class="btn-primary md-ink-ripple"
              :class="{ 'global-disabled': !perfectmatch }"
              @click.prevent="FirstGeneration"
            >Personalize</a>
          </div>
        </div>
      </div>
    
    </div>
  </div>
  <div v-if="loader" class="template-loader-hiw hide">
    <div class="loader-overlay"></div>
    <div class="progress-bar-wrapper">
      <img
        src="../../assets/check-circle.svg"
        v-if="finished"
        alt="Check Circle"
        width="48"
        height="48"
        class="icon-check-circle hide"
      >
      <p class="progress-text step1">{{loadermsg}}</p>
      <div v-if="!finished" class="progress-bar">
        <div class="progress-linear"></div>
        <div class="progress-gradient progress-animate"></div>
      </div>
    </div>
  </div>
  <ConfirmBox
    ref="confirmcomp"
    :showmodal="showCnfrmPopUp"
    :actionmessage="actionMsg"
    @cancelAction="NO()"
    @confirmAction="Delete()"
  ></ConfirmBox>
  <div
      id="overprompt"
      role="dialog"
      tabindex="-1"
      class="modal fade imgPreview"
      aria-modal="true"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title mb-4"></h3>
            <button type="button" data-dismiss="modal" class="close">×</button>
          </div>
          <div class="modal-body">
            <h3>You have exhausted all your credits</h3>
          </div>
        </div>
      </div>

    </div>
</div>
</template>
  <script>
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import myWorker from "@/config";
import Header from "@/components/Header.vue";
import Leftmenu from "@/components/Leftmenu.vue";
import ConfirmBox from "@/components/MessagePopup/ConfirmPopup";
export default {
  created() {
    if (localStorage.getItem("cvjd_package")) {
      var cv = JSON.parse(localStorage.getItem("cvjd_package"));
      if (parseInt(cv.usedNoCvjdMatch) >= parseInt(cv.maxNocvjdMatch)) {
        this.allow_cvjd_match = false;
      } else {
        this.allow_cvjd_match = true;
      }
    } else {
      this.allow_cvjd_match = false;
    }
    if (localStorage.getItem("cv_package")) {
      var cv = JSON.parse(localStorage.getItem("cv_package"));
      if (parseInt(cv.usedNoCvGenerate) >= parseInt(cv.maxNoCvGenerate)) {
        this.allow_custom_cv = false;
      } else {
        this.allow_custom_cv = true;
      }
    } else {
      this.allow_custom_cv = false;
    }
    this.currentstep=1;
    this.Binddata();
  },
  components: {
    Header,
    Leftmenu,
    ConfirmBox
  },
  data() {
    return {
     work:[],
     Education:[],
     uniqueId:"",
     skill:"None",
     summary:"None",
     match_percentage:"",
     Job_desc:"",
     loadermsg:"",
     loader:false,
     finished:false,
     Reason:"",
     currentstep:1,
     err_jd:false,
     allow_cvjd_match:false,
     allow_custom_cv:false,
     perfectmatch:false,
     actionMsg:"",
     delete_what:"",
     showCnfrmPopUp:false
    };
  },
  methods: {
    Binddata() {
      debugger;
      var Data = JSON.parse(localStorage.getItem("UserCV"));
      this.work = (Data.workExperience);
      this.Education = (Data.education);
      this.skill = Data.skills;
      this.summary = Data.summary;
      this.uniqueId = Data.uniqueId;
    },
    Back(){
      this.currentstep=1;
      this.Job_desc="";
    },
    NO(){
      $("#overprompt").modal("hide");
      this.showCnfrmPopUp = false;
      	this.$router.push({
          path: "/dashboard",
        });
    },
    updatelimit(name, number) {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var file = {
        Uniqueid: localStorage.getItem("package_uniqueid"),
        Name: name,
        No: number
      };
      axios({
        method: "post",
        url: rest_service.baseURL + "/User/UpdateUsageBasedOnType",
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        }
      })
        .then(function(response) {
          if (response) {
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        })
        .finally(() => {
          this.Getlimit();
        });
    },
    Getlimit() {
      debugger;
      var self = this;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserActivePackage",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken
        },
        params: {
          email: uid.user.email
        }
      })
        .then(function(response) {
          if (response.data) {
            if (response.data.result.length > 0) {
              self.IsCvjdMatchAllowed(response.data.result);
              self.IsCvAllowed(response.data.result);
            }
          } else {
            return null;
          }
        })
        .catch(function(error) {
          console.log(error);
          return error;
        });
    },
    submittocustomize() {
      var self = this;
      if (self.Job_desc != "") {
        self.loadermsg = "personalizing....";
        self.loader = true;
        self.finished = false;
        var file = {
          work_experience: JSON.stringify(self.work),
          education: JSON.stringify(self.Education),
          skills: self.skill,
          job_description: self.Job_desc
        };
        axios({
          method: "post",
          url: rest_service.PythonAPI + "/customize_cv_api",
          //url: "https://dev-resumebuilder.careercubicle.com/analyze_cv",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          data: file
        }).then(function(response) {
          if (response.data) {
            debugger;
            var Content = response.data.content;
            localStorage.setItem("cv_generation",Content);    
            var cv = JSON.parse(localStorage.getItem("cv_package"));
            var number = parseInt(cv.usedNoCvGenerate) + 1;
            self.updatelimit("cv", number);
            self.loadermsg = "Finished";
            self.finished = true;            
            setTimeout(xy => {
              self.closeloader();
            }, 3000);
            self.$router.push({
                              name: "CV",
                              params: { id:self.uniqueId },
                            });

          } else {
            self.loader = false;
            return null;
          }
        });
      } else {
        self.err_jd = true;
      }
    },
    CVjdMatch(){
      var self = this;
      if (self.Job_desc != "") {
      if(localStorage.getItem("cvjd_package")){
      var cv = JSON.parse(localStorage.getItem("cvjd_package"));
      var number =parseInt(cv.maxNocvjdMatch)-parseInt(cv.usedNoCvjdMatch);
      self.actionMsg = "Do you want to use 1 credit out of remaining "+number+" credit(s)?";
      self.delete_what = "CVJDMatch";
      self.showCnfrmPopUp = true;
      }
      else{
        $("#overprompt").modal("show");
        setTimeout(y => {
              self.NO();
            }, 2000);
      }
      }
      else{
         self.err_jd = true;
      }
    },
    FirstGeneration(){
      var cv = JSON.parse(localStorage.getItem("cv_package"));
      var number =parseInt(cv.maxNoCvGenerate)-parseInt(cv.usedNoCvGenerate);
      this.actionMsg = "Do you want to use 1 credit out of remaining "+number+" credit(s)?";
      this.delete_what = "Generate";
      this.showCnfrmPopUp = true;
    },
    Delete() {
      this.showCnfrmPopUp = false;
      if (this.delete_what == "CVJDMatch") {
        this.submittomatch();
      }
      if (this.delete_what == "Generate") {
        this.submittocustomize();
      }
    },
    IsCvAllowed(item){
      var self = this;
      localStorage.removeItem("package_uniqueid");
      localStorage.removeItem("cv_package");
      item.forEach(e=>{
          debugger;
          if(e.saveCv){
            if( (parseInt(e.maxNoCvGenerate))>(parseInt(e.usedNoCvGenerate)) ){
              localStorage.setItem("package_uniqueid", e.purchasedPackageUniqueId);  
              localStorage.setItem("cv_package",JSON.stringify(e));    
              return;      
            }     
                  
          }
        })
    },
    IsCvjdMatchAllowed(item){
      var self = this;
      self.allow_cvjd_match = false;  
      localStorage.removeItem("cvjd_package");
      item.forEach(e=>{
          debugger;
          if(e.matchJobDescriptionCv){
            if( (parseInt(e.maxNocvjdMatch))>(parseInt(e.usedNoCvjdMatch)) ){
              self.allow_cvjd_match = true;  
              localStorage.setItem("cvjd_package",JSON.stringify(e));    
              return;      
            }     
                  
          }
        })
    },
    submittomatch() {
      var self = this;
      if (self.Job_desc != "") {
        self.loadermsg = "Calculating....";
        self.loader = true;
        self.finished = false;
        var file = {
          work_experience: (self.work),
          education: (self.Education),
          skills: self.skill,
          job_description: self.Job_desc,
          summary: self.summary
        };
        axios({
          method: "post",
          url: rest_service.PythonAPI + "/cv_jd_percentage",
          //url: "https://dev-resumebuilder.careercubicle.com/analyze_cv",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          data: file
        }).then(function(response) {
          if (response.data.content) {
            debugger;
            var Data = (response.data);
            const sanitizedJSONString = Data.content.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
            var Content = JSON.parse(sanitizedJSONString);
            self.match_percentage=Content.match_percentage;
           // self.Reason = Content.reason;
            var percent;
            if(Content.match_percentage!=''){
             percent= parseInt(Content.match_percentage);
            }
            if(percent>=80){
              self.perfectmatch = true;
              self.Reason = self.replaceNumericalValuesWithText(
                Content.reason,
                "high"
              );
            }
            else if(percent<80&&percent>=50){
              self.perfectmatch = false;
              self.match_percentage = "Low";
              self.Reason = self.replaceNumericalValuesWithText(
                Content.reason,
                "Low"
              );
            }
            else{
              self.perfectmatch = false;
              self.match_percentage = "Very Low";
              self.Reason = self.replaceNumericalValuesWithText(
                Content.reason,
                "Very Low"
              );
            }
            var cv = JSON.parse(localStorage.getItem("cvjd_package"));
            var number = parseInt(cv.usedNoCvjdMatch) + 1;
            self.updatelimit("jdmatch", number);
            self.loadermsg = "Finished";
            self.finished = true;
            setTimeout(xy => {
              self.closeloader();
            }, 3000);
            self.currentstep=2;
          } else {
            self.loadermsg = "Something wrong. Try after sometime.";
            setTimeout(xy => {
              self.closeloader();
            }, 3000);
            
            return null;
          }
        });
      } else {
        self.err_jd = true;
      }
    },
    closeloader() {
      var self = this;
      self.loader = false;
      self.finished = false;
    },
    replaceNumericalValuesWithText(sentence, replacetxt) {
      // Use a regular expression to find numerical values in the sentence
      const regex = /\d+/g;

      // Replace each numerical value with your desired text
      const replacedSentence = sentence.replace(regex, replacetxt);

      return replacedSentence;
    },
  },
  watch:{
     Job_desc(val){
      if(this.Job_desc!="")
      this.err_jd=false;
    },
  }
};
</script>

<style>
.required {
  color: red;
  margin-left: 5px; /* Add some spacing between the label text and the asterisk */
}
/* .jd-job-desc {
    max-width: 700px;
    width: 100%;
    margin: 50px auto 0;
} */
.enter-jd-job {
    max-width: 900px !important;
    width: 100%;
    margin: 50px auto 0;
    background: #f1f1f1;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
}
.enter-jd-job .job-desc {
    max-width: 900px;
    margin: 10px auto;
    width: 100%;
}
.enter-jd-job .job-desc textarea {
    width: 100%;
    max-height: 400px;
    height: 100% !important;
    min-height: 300px;
    margin: 10px 0 0;
    resize: none;
    padding: 10px;
    color: #000;
}
.resume-builder-main .resume-builder-right .profile-match > p {
    font-size: 24px;
}
.resume-builder-main .resume-builder-right .profile-match > p span {
    font-weight:500;
}
.resume-builder-main .resume-builder-right .profile-match .resume-build  p {
    font-size: 18px;
    line-height: 160%;
}
</style>