<template>
  <div>
    <div class="top-header">
      <div class="top-left">
        <div class="logo">
          <a href="/Dashboard"><img src="/img/CC-Logo-White.1144d714.svg" alt="" /></a>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <div>
        <h1>Payment completed successfully</h1>
        <img
          style="max-width: 80%"
          title="Careercubicle"
          alt="Careercubicle"
          src="https://careercubicle.com/Images/success.png"
          border="0"
        />
      </div>
      <!-- <div class="signin-btn-hold">
        <a href="#" @click.prevent="Dashboard()" class="primary-btn ripple signin-btn"
          >Downlaod CV</a
        >
      </div> -->

      <h4>Your changes will be effective in a few minutes.</h4>
      <h4>
        Check your subscription details here >
        <a href="/dashboard">Dashboard</a>
      </h4>
      <br />
      <br />
      <h6>
        In case you have any questions or clarifications, you can email
        <a href="mailto:contact@careercubicle.com">contact@careercubicle.com</a>
      </h6>
      <div v-if="showloader">
        <div
          class="processing_popup upload full-spinner"
          style="visibility: visible"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    <script>
import Axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import myWorker from "@/config";
export default {
  created() {
    if (window.localStorage.getItem("accesstoken")) {
      this.authenticationToken = window.localStorage.getItem("accesstoken");
      this.Email = localStorage.getItem("Email");
    }
    this.GetPackage();
  },
  components: {},
  data() {
    return {
      showloader: false,
      authenticationToken: "",
      Email: "",
    };
  },
  methods: {
    Dashboard() {
      this.$router.push({ path: "Dashboard" });
    },
    GetPackage() {
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      Axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserPackage",

        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            var dataToStore = JSON.stringify(response.data.result);
            localStorage.setItem("UserPackageDetails", dataToStore);
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
  },
};
</script>
   