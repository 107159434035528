<template>
  <div>
    <div class="top-header">
      <div class="top-left">
        <div class="logo">
          <a href="/Dashboard"><img src="/img/CC-Logo-White.1144d714.svg" alt="" /></a>
        </div>
      </div>
    </div>
    <div style="text-align: center">
      <div>
        <h1>Payment Failed</h1>
        <img
          style="max-width: 80%"
          title="Careercubicle"
          alt="Careercubicle"
          src="https://careercubicle.com/Images/failed.png"
          border="0"
        />
      </div>
      <div class="signin-btn-hold">
        <a
          href="#"
          @click.prevent="Retry()"
          class="primary-btn ripple signin-btn"
          >Retry</a
        >
      </div>
    </div>
  </div>
</template>
      <script>
import Axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import myWorker from "@/config";
export default {
  created() {},
  components: {},
  data() {
    return {};
  },
  methods: {
    Retry() {
      debugger;
      var Email = localStorage.getItem("Email");
      this.$router.push({ path: "ChangePlan" });
    },
  },
};
</script>
     