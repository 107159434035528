<template>
  <div>
    <div class="top-header">
      <div class="top-left">
        <div class="logo">
          <a href="/Dashboard"
            ><img src="/img/CC-Logo-White.1144d714.svg" alt=""
          /></a>
        </div>
      </div>
    </div>
    <div class="coverfinalletter">
      <div class="container">
        <div class="row">
          <div class="col-2">
            <ul>
              <li><h3>LETTER SECTIONS</h3></li>
              <li @click="EditPopup('NameCon')"><a>Name & Contact</a></li>
              <li @click="EditPopup('Date')">Date</li>
              <li @click="EditPopup('Recipient')">Recipient</li>
              <li @click="EditPopup('Subject')">Subject</li>
              <li @click="EditPopup('Greeting')">Greeting</li>
              <li @click="EditPopup('Opening')">Opening</li>
              <li @click="EditPopup('Body')">Letter Body</li>
              <li v-if="PaidUser" @click="EditPopup('Desc')">Description</li>
              <li @click="EditPopup('Calltoaction')">Call to Action</li>
              <li @click="EditPopup('Closing')">Closing</li>
              <li v-if="PaidUser" @click="EditPopup('inputjd')">
                View job description
              </li>
            </ul>
          </div>
          <div class="col-8">
            <div v-show="ResumeTemplate == 'CoverLetter_1'">
              <CoverLetter_1
                :resume="resumeobj"
                ref="Template1"
              ></CoverLetter_1>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_2'">
              <CoverLetter_2
                :resume="resumeobj"
                ref="Template2"
              ></CoverLetter_2>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_3'">
              <CoverLetter_3
                :resume="resumeobj"
                ref="Template3"
              ></CoverLetter_3>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_4'">
              <CoverLetter_4
                :resume="resumeobj"
                ref="Template4"
              ></CoverLetter_4>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_5'">
              <CoverLetter_5
                :resume="resumeobj"
                ref="Template5"
              ></CoverLetter_5>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_6'">
              <CoverLetter_6
                :resume="resumeobj"
                ref="Template6"
              ></CoverLetter_6>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_7'">
              <CoverLetter_7
                :resume="resumeobj"
                ref="Template7"
              ></CoverLetter_7>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_8'">
              <CoverLetter_8
                :resume="resumeobj"
                ref="Template8"
              ></CoverLetter_8>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_9'">
              <CoverLetter_9
                :resume="resumeobj"
                ref="Template9"
              ></CoverLetter_9>
            </div>
            <div v-show="ResumeTemplate == 'CoverLetter_10'">
              <CoverLetter_10
                :resume="resumeobj"
                ref="Template10"
              ></CoverLetter_10>
            </div>
          </div>
          <div class="col-2">
            <div class="dropdown">
              <span type="button" data-toggle="dropdown">
                <i class="material-icons">download</i> Download
              </span>
              <ul class="dropdown-menu">
                <li>
                  <a
                    href="javascript:void(0);"
                    @click.prevent="downloadCoverletterDoc()"
                    ><i class="fa fa-file-word-o"></i> Download Word</a
                  >
                </li>
                <li>
                  <a
                    href="javascript:void(0);"
                    @click.prevent="downloadCoverletterPDF()"
                    ><i class="fa fa-file-pdf-o"></i> Download Pdf</a
                  >
                </li>
              </ul>
            </div>
            <ul class="right-ul">
              <li @click="PrintCoverletterPDF()">
                <i class="material-icons">print</i> Print
              </li>
              <li @click="SendEmailCoverLetter()">
                <i class="material-icons">email</i> Email
              </li>
            </ul>
            <div>
              <button
                v-b-tooltip.hover
                title="Regenerate your cover letter"
                class="btn-primary md-ink-ripple"
                @click="Isjdallowed()"
              >
                Regenerate
              </button>
            </div>
            <br />
            <div v-if="SaveorUpdate == 'Update'">
              <button @click="Update()" class="btn-primary md-ink-ripple">
                Save
              </button>
            </div>
            <div v-if="SaveorUpdate == 'Save'">
              <button @click="Save()" class="btn-primary md-ink-ripple">
                Save
              </button>
            </div>

            <br />
            <div>
              <button @click="Dashboard()" class="btn-primary md-ink-ripple">
                Dashboard
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="NameCon"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Name and Contact</h3>
                <h5>Enter your contact information</h5>
              </div>
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >First Name<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="First_Name"
                        name="Your First Name"
                        placeholder="e.g. John"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="lName"
                        >Last Name<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Last_Name"
                        name="Your Last Name"
                        placeholder="e.g. smith"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="Last Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Profession<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Profession"
                        name="Your First Name"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >City<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="City"
                        name="Your First Name"
                        placeholder="e.g. Bengaluru"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >State/Province<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="State"
                        name="Your First Name"
                        placeholder="e.g. Karnataka"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Pincode<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Zip"
                        name="Your First Name"
                        placeholder="e.g. 94102"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Phone Number<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="PhoneNumber"
                        name="Your First Name"
                        placeholder="e.g 9876543210"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Email<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Email"
                        name="Your First Name"
                        placeholder="e.g john@gmail.com"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveNameCon()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Date"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Date</h3>
                <h5>Enter the date of your letter</h5>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label class="control-label" for="fName"
                      >Date<span></span
                    ></label>
                    <input
                      type="text"
                      v-model="coverletterdate"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button class="btn-primary md-ink-ripple" @click="SaveDate()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Recipient"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Recipient</h3>
                <h5>Enter your recipient's information</h5>
              </div>
              <div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >First Name<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_First_Name"
                        name="Your First Name"
                        placeholder="e.g. John"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="lName"
                        >Last Name<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_Last_Name"
                        name="Your Last Name"
                        placeholder="e.g. smith"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="Last Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Company Name<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_Company"
                        name="Your First Name"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >City<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_City"
                        name="Your First Name"
                        placeholder="e.g. Bengaluru"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >State/Province<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_State"
                        name="Your First Name"
                        placeholder="e.g. Karnataka"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Pincode<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_Zip"
                        name="Your First Name"
                        placeholder="e.g. 94102"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Phone Number<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_PhoneNumber"
                        name="Your First Name"
                        placeholder="e.g 9876543210"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Email<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="Recipient_Email"
                        name="Your First Name"
                        placeholder="e.g john@gmail.com"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveRecipient()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Subject"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Subject</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="overflow-scroll">
                    <div class="form-group">
                      <ul v-for="item in subjectlist" class="listing-sec">
                        <li>
                          <div @click="Edit_Subject(item)" class="listing-box">
                            <span
                              v-show="!subject.includes(item)"
                              class="fa fa-plus"
                            ></span>

                            <span
                              v-show="subject.includes(item)"
                              class="red fa fa-minus"
                            ></span>
                            <div v-html="extractText(item)"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <ckeditor
                      v-model="subject"
                      :config="editorConfig"
                    ></ckeditor>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveSubject()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Greeting"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Greeting</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="overflow-scroll">
                    <div class="form-group">
                      <ul v-for="item in greetinglist" class="listing-sec">
                        <li>
                          <div @click="Edit_Greeting(item)" class="listing-box">
                            <span
                              v-show="!greeting.includes(item)"
                              class="fa fa-plus"
                            ></span>

                            <span
                              v-show="greeting.includes(item)"
                              class="red fa fa-minus"
                            ></span>
                            <div v-html="extractText(item)"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <ckeditor v-model="greeting"></ckeditor>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveGreeting()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Opening"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Opening</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="overflow-scroll">
                    <div class="form-group">
                      <ul v-for="item in openinglist" class="listing-sec">
                        <li>
                          <div @click="Edit_Opening(item)" class="listing-box">
                            <span
                              v-show="!opening.includes(item)"
                              class="fa fa-plus"
                            ></span>

                            <span
                              v-show="opening.includes(item)"
                              class="red fa fa-minus"
                            ></span>
                            <div v-html="extractText(item)"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <ckeditor v-model="opening"></ckeditor>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveOpening()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="letterbody"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Letter Body</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="overflow-scroll">
                    <div class="form-group">
                      <ul v-for="item in bodieslist" class="listing-sec">
                        <li>
                          <div @click="Edit_body(item)" class="listing-box">
                            <span
                              v-show="!letterbody.includes(item)"
                              class="fa fa-plus"
                            ></span>

                            <span
                              v-show="letterbody.includes(item)"
                              class="red fa fa-minus"
                            ></span>
                            <div v-html="extractText(item)"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <ckeditor v-model="letterbody"></ckeditor>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button class="btn-primary md-ink-ripple" @click="SaveBody()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="descsec"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-6">
                <h3>Description</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="overflow-scroll">
                    <div class="form-group">
                      <ul v-for="item in desclist" class="listing-sec">
                        <li>
                          <div @click="Edit_dec(item)" class="listing-box">
                            <span
                              v-show="!desc.includes(item)"
                              class="fa fa-plus"
                            ></span>

                            <span
                              v-show="desc.includes(item)"
                              class="red fa fa-minus"
                            ></span>
                            <div v-html="extractText(item)"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <ckeditor v-model="desc"></ckeditor>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <!-- <button
                  v-if="jdaccess"
                  class="btn-primary md-ink-ripple"
                  @click="Isjdallowed()"
                >
                  Regenerate
                </button> -->
                <button class="btn-primary md-ink-ripple" @click="Savedesc()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Calltoaction"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Call to Action</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="overflow-scroll">
                    <div class="form-group">
                      <ul v-for="item in calltoactionlist" class="listing-sec">
                        <li>
                          <div
                            @click="Edit_Calltoaction(item)"
                            class="listing-box"
                          >
                            <span
                              v-show="!calltoaction.includes(item)"
                              class="fa fa-plus"
                            ></span>

                            <span
                              v-show="calltoaction.includes(item)"
                              class="red fa fa-minus"
                            ></span>
                            <div v-html="extractText(item)"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <ckeditor v-model="calltoaction"></ckeditor>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveCallToAction()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="Closing"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Closing</h3>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="overflow-scroll">
                    <div class="form-group">
                      <ul v-for="item in closinglist" class="listing-sec">
                        <li>
                          <div @click="Edit_Closing(item)" class="listing-box">
                            <span
                              v-show="!closing.includes(item)"
                              class="fa fa-plus"
                            ></span>

                            <span
                              v-show="closing.includes(item)"
                              class="red fa fa-minus"
                            ></span>
                            <div v-html="item"></div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div>
                    <ckeditor v-model="closing"></ckeditor>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveClosing()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="inputjd"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Job description</h3>
              </div>
              <label v-if="resumeobj.inputjobdescription">{{
                resumeobj.inputjobdescription
              }}</label>
              <label v-else>
                No Job Description is associated with this cover letter
                yet.</label
              >
            </div>
          </div>
        </div>
      </div>
      <div
        id="Working_Style"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-3">
                <h3>Working Style</h3>
              </div>
              <div class="row">
                <div class="col-md-4">
                  <button
                    :class="{ selected: SelectedworkingStyle === 'Artistic' }"
                    class="button-box"
                    @click="SelectWorkStyle('Artistic')"
                  >
                    <b>Artistic</b>
                    You thrive in dynamic environments driven by innovation and
                    creativity.
                  </button>
                </div>
                <div class="col-md-4">
                  <button
                    :class="{
                      selected: SelectedworkingStyle === 'Enterprising',
                    }"
                    class="button-box"
                    @click="SelectWorkStyle('Enterprising')"
                  >
                    <b>Enterprising </b>
                    You’re accustomed to leading teams with empowering and
                    decisive task delegation.
                  </button>
                </div>
                <div class="col-md-4">
                  <button
                    :class="{
                      selected: SelectedworkingStyle === 'Investigative',
                    }"
                    class="button-box"
                    @click="SelectWorkStyle('Investigative')"
                  >
                    <b>Investigative </b>
                    You bring a resourceful approach with a knack for
                    problem-solving.
                  </button>
                </div>
                <div class="col-md-4">
                  <button
                    :class="{ selected: SelectedworkingStyle === 'Organized' }"
                    class="button-box"
                    @click="SelectWorkStyle('Organized')"
                  >
                    <b>Organized </b>
                    You bring structure and focus to streamline tasks.
                  </button>
                </div>
                <div class="col-md-4">
                  <button
                    :class="{ selected: SelectedworkingStyle === 'Practical' }"
                    class="button-box"
                    @click="SelectWorkStyle('Practical')"
                  >
                    <b>Practical </b>
                    You go above and beyond to meet goals and ensure timely task
                    completion..
                  </button>
                </div>
                <div class="col-md-4">
                  <button
                    :class="{
                      selected: SelectedworkingStyle === 'Service-Oriented',
                    }"
                    class="button-box"
                    @click="SelectWorkStyle('Service-Oriented')"
                  >
                    <b>Service-Oriented </b>
                    You excel in collaborative situations and enjoy helping
                    others.
                  </button>
                </div>
              </div>

              <!-- <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <ul>
                    <li class="button-box" @click="SelectWorkStyle('Artistic')">
                      <b>Artistic</b>
                      You thrive in dynamic environments driven by innovation
                      and creativity.
                    </li>
                    <li
                      class="button-box"
                      @click="SelectWorkStyle('Enterprising')"
                    >
                      <b>Enterprising </b>
                      You’re accustomed to leading teams with empowering and
                      decisive task delegation.
                    </li>
                    <li
                      class="button-box"
                      @click="SelectWorkStyle('Investigative')"
                    >
                      <b>Investigative </b> You bring a resourceful approach
                      with a knack for problem-solving.
                    </li>
                    <li
                      class="button-box"
                      @click="SelectWorkStyle('Organized')"
                    >
                      <b>Organized </b> You bring structure and focus to
                      streamline tasks.
                    </li>
                    <li
                      class="button-box"
                      @click="SelectWorkStyle('Practical')"
                    >
                      <b>Practical</b> You go above and beyond to meet goals and
                      ensure timely task completion..
                    </li>
                    <li
                      class="button-box"
                      @click="SelectWorkStyle('Service-Oriented')"
                    >
                      <b>Service-Oriented</b> You excel in collaborative
                      situations and enjoy helping others.
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button
                  class="btn-primary md-ink-ripple"
                  @click="SaveWorkStyle()"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Templatesection"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-4">
                <h3>Change Template</h3>
              </div>

              <div class="col-md-12">
                <div class="template-sec">
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_1
                        :resume="resumeobj"
                        ref="Template1_1"
                      ></CoverLetter_1>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_1')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_2
                        :resume="resumeobj"
                        ref="Template2_1"
                      ></CoverLetter_2>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_2')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_3
                        :resume="resumeobj"
                        ref="Template3_1"
                      ></CoverLetter_3>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_3')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_4
                        :resume="resumeobj"
                        ref="Template4_1"
                      ></CoverLetter_4>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_4')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_5
                        :resume="resumeobj"
                        ref="Template5_1"
                      ></CoverLetter_5>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_5')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_6
                        :resume="resumeobj"
                        ref="Template6_1"
                      ></CoverLetter_6>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_6')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_7
                        :resume="resumeobj"
                        ref="Template7_1"
                      ></CoverLetter_7>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_7')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_8
                        :resume="resumeobj"
                        ref="Template8_1"
                      ></CoverLetter_8>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_8')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_9
                        :resume="resumeobj"
                        ref="Template9_1"
                      ></CoverLetter_9>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_9')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                  <div class="template">
                    <div class="inner-coverletter">
                      <CoverLetter_10
                        :resume="resumeobj"
                        ref="Template10_1"
                      ></CoverLetter_10>
                      <button
                        class="btn-select-template"
                        @click="Selecttemplate('CoverLetter_10')"
                      >
                        Select Template
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="Regeneratejdsection"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="mb-4">
                <h3>Regenerate job description</h3>
              </div>
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Work experience<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="WorkEXPRegenerateJD"
                        name="Your First Name"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Education<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="EducationRegenerateJD"
                        name="Your First Name"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Skills<span></span></label
                      ><input
                        type="text"
                        width="25%"
                        v-model="SkillRegenerateJD"
                        name="Your First Name"
                        maxlength="35"
                        class="form-control"
                        id="fName"
                        aria-label="First Name"
                        aria-required="false"
                        value=""
                      />
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Job description<span></span
                      ></label>
                      <textarea
                        id="w3review"
                        name="w3review"
                        v-model="JDRegenerateJD"
                        rows="6"
                        cols="50"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <button class="btn-primary md-ink-ripple" @click="GenerateJD()">
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="RegeneratejdsectionV2"
        role="dialog"
        tabindex="-1"
        class="modal fade"
        aria-modal="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <!-- <div class="mb-4">
                <h3>Regenerate job description</h3>
              </div> -->
              <div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="control-label" for="fName"
                        >Do you have job description?<span></span
                      ></label>
                      <textarea
                        id="w3review"
                        name="w3review"
                        v-model="resumeobj.inputjobdescription"
                        placeholder="No Job Description is associated with this cover letter"
                        rows="6"
                        cols="80"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-between mt-3">
                <button class="btn-primary md-ink-ripple" data-dismiss="modal">
                  Cancel
                </button>
                <div class="d-flex align-items-center">
                  <button
                    class="btn-primary md-ink-ripple"
                    @click="GetjobDescriptionNew()"
                  >
                    Skip & Generate
                  </button>
                  <i
                    class="fa fa-info-circle fa-2x ml-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="It will regenerate with skills, strengths, recent job title, desired job title, Desired company, experience and working style"
                  ></i>
                </div>
                <div class="d-flex align-items-center">
                  <button
                    class="btn-primary md-ink-ripple"
                    @click="GenerateorRegenerate()"
                  >
                    Generate
                  </button>
                  <i
                    class="fa fa-info-circle fa-2x ml-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="It will regenerate with job description and other information"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-toolbar">
        <button @click="ChangeTemplate()" class="btn-primary md-ink-ripple">
          Template
        </button>
        <button @click="Workingstyleselect()" class="btn-primary md-ink-ripple">
          Letter type
        </button>
      </div>
      <transition name="fade">
        <Messages
          :datamessage="sucessmsgtext"
          :iserror="iserrormsg"
          @closemsg="closeMessage"
          :isVisible="isdisplaymsg"
        ></Messages>
      </transition>
      <div v-if="showloader">
        <div
          class="processing_popup upload full-spinner"
          style="visibility: visible"
        >
          <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loader" class="template-loader-hiw hide">
      <div class="loader-overlay"></div>
      <div class="progress-bar-wrapper">
        <img
          src="../../assets/check-circle.svg"
          v-if="finished"
          alt="Check Circle"
          width="48"
          height="48"
          class="icon-check-circle hide"
        />
        <p class="progress-text step1">{{ loadermsg }}</p>
        <div v-if="!finished" class="progress-bar">
          <div class="progress-linear"></div>
          <div class="progress-gradient progress-animate"></div>
        </div>
      </div>
    </div>
    <ConfirmBox
      ref="confirmcomp"
      :showmodal="showCnfrmPopUp"
      :actionmessage="actionMsg"
      @cancelAction="NO()"
      @confirmAction="Yesconfirm()"
    ></ConfirmBox>
  </div>
</template>
<script>
import Messages from "@/components/MessagePopup/Messages.vue";
import axios from "axios";
import ConfirmBox from "@/components/MessagePopup/ConfirmPopup";
import rest_service from "@/api/rest_service_api.js";
import CoverLetter_1 from "@/components/CoverLetterTemplates/CoverLetter_1.vue";
import CoverLetter_2 from "@/components/CoverLetterTemplates/CoverLetter_2.vue";
import CoverLetter_3 from "@/components/CoverLetterTemplates/CoverLetter_3.vue";
import CoverLetter_4 from "@/components/CoverLetterTemplates/CoverLetter_4.vue";
import CoverLetter_5 from "@/components/CoverLetterTemplates/CoverLetter_5.vue";
import CoverLetter_6 from "@/components/CoverLetterTemplates/CoverLetter_6.vue";
import CoverLetter_7 from "@/components/CoverLetterTemplates/CoverLetter_7.vue";
import CoverLetter_8 from "@/components/CoverLetterTemplates/CoverLetter_8.vue";
import CoverLetter_9 from "@/components/CoverLetterTemplates/CoverLetter_9.vue";
import CoverLetter_10 from "@/components/CoverLetterTemplates/CoverLetter_10.vue";
import Multiselect from "vue-multiselect";
import Autocomplete from "v-autocomplete";
import CKEditor from "ckeditor4-vue";
import moment from "moment";
export default {
  components: {
    ckeditor: CKEditor.component,
    Multiselect,
    CoverLetter_1,
    CoverLetter_2,
    CoverLetter_3,
    CoverLetter_4,
    CoverLetter_5,
    CoverLetter_6,
    CoverLetter_7,
    CoverLetter_8,
    CoverLetter_9,
    CoverLetter_10,
    Autocomplete,
    Messages,
    ConfirmBox,
  },
  created() {
    debugger;
    if (window.localStorage.getItem("accesstoken")) {
      this.authenticationToken = window.localStorage.getItem("accesstoken");
    }
    this.PaidUser = false;
    if (this.CheckPackageV2()) {
      this.PaidUser = true;
    }
    if (this.CheckPackageV3()) {
      this.jdaccess = true;
    }
    if (localStorage.getItem("resumeobj")) {
      this.SaveUpdateButton();
      this.resumeobj = JSON.parse(localStorage.getItem("resumeobj"));
      this.PageCall();
      debugger;
      this.APIorLocaStorageBindData(this.resumeobj);
    } else {
      var coverletterid = this.$route.query.coverletterid;
      if (coverletterid != null) {
        localStorage.setItem("coverletterid", coverletterid);
        this.SaveUpdateButton();
        this.GetCoverLetter(coverletterid);
      }
    }
  },
  data() {
    return {
      ext_desc: "",
      jdaccess: false,
      actionMsg: "",
      delete_what: "",
      showCnfrmPopUp: false,
      usedCoverLetterGenerate: "",
      remainingCoverLetterGenerate: "",
      PaidUser: false,
      JDRegenerateJD: "",
      SkillRegenerateJD: "",
      EducationRegenerateJD: "",
      WorkEXPRegenerateJD: "",
      loader: false,
      finished: false,
      loadermsg: "",
      authenticationToken: "",
      showloader: false,
      sucessmsgtext: "",
      iserrormsg: false,
      isdisplaymsg: false,
      SaveorUpdate: "",
      workingStyleList: [
        "<b>Artistic</b><br>You thrive in dynamic environments driven by innovation and creativity.",
        " <b>Enterprising </b><br>You’re accustomed to leading teams with empowering and decisive task delegation.",
      ],
      closinglist: [
        "Sincerely,<br>[Your First Name] [Your Last Name]",
        "Thank you for your valued time,<br>[Your First Name] [Your Last Name]",
        "Thank you for your valued time,<br>[Your First Name] [Your Last Name]",
        "Thank you, in advance, for your consideration.<br><br>Best regards,<br>[Your First Name] [Your Last Name]",
        "Until then,<br>[Your First Name] [Your Last Name]",
        "Yours sincerely,<br>[Your First Name] [Your Last Name]",
        "Have a lovely day.<br><br>Sincerely,<br>[Your First Name] [Your Last Name]",
        "I am ready to put my expertise to work for the benefit of your organization.<br><br>Regards,<br>[Your First Name] [Your Last Name]",
        "I appreciate your consideration.<br>Best regards,<br>[Your First Name] [Your Last Name]",
        "I look forward to your reply.<br><br>Sincerely,<br>[Your First Name] [Your Last Name]",
        "I welcome the opportunity to contribute my skills to your team.<br><br>With regards,<br>[Your First Name] [Your Last Name]",
        "Looking forward to hearing from you.<br><br>Sincerely,[Your First Name] [Your Last Name]",
        "Thank you for your interest,<br>[Your First Name] [Your Last Name]",
      ],
      closing: "",
      calltoactionlist: [],
      calltoaction: "",
      letterbody: "",
      bodieslist: [],
      desclist: [],
      desc: "",
      opening: "",
      openinglist: [],
      greeting: "",
      greetinglist: [
        "Dear [Ms. or Mr. Last Name],",
        "Dear Hiring Manager,",
        "Dear Hiring Professional,",
        "Dear Mr./Mrs. [Last Name],",
        "Dear Recruiter,",
        "Dear Search Committee,",
        "To [Full Name],",
        "To the Vice President,",
        "Dear [First Name] [Last Name],",
        "Dear [Title] [Last Name],",
        "Dear Director of Human Resources,",
      ],
      subjectlist: [
        "RE: Experienced [Job Title] for hire",
        "RE: Your [Date] job ad for [Job Title]",
        "RE: Your [Date] job ad for [Job Title]",
        "RE: [Job Title] Guru for Hire [Req #]",
        "Your ad for a [Position], City Times, 11/20/XX",
        "RE: [Date] ad for a [Job Title]",
        "RE: [Job Title]",
        "RE: [Job Title][Code Number]",
        "RE: [Job Title] Guru for Hire [Req #]",
        "RE: [Posting Location][Date] ad for [Job Title]",
      ],
      subject: "",

      // editorConfig: {
      //   toolbar: {
      //     items: [
      //       "heading",
      //       "|",
      //       "bold",
      //       "italic",
      //       "|",
      //       "bulletedList",
      //       "numberedList",
      //       "|",
      //       "insertTable",
      //       "|",
      //       "imageUpload",
      //       "|",
      //       "undo",
      //       "redo",
      //     ],
      //   },
      // },
      resumeobj: { User_Profile: {} },
      ResumeTemplate: "",
      coverletterdate: "",
      Email: "",
      PhoneNumber: "",
      Zip: "",
      State: "",
      City: "",
      Profession: "",
      First_Name: "",
      Last_Name: "",
      Recipient_First_Name: "",
      Recipient_Last_Name: "",
      Recipient_Company: "",
      Recipient_City: "",
      Recipient_State: "",
      Recipient_Zip: "",
      Recipient_PhoneNumber: "",
      Recipient_Email: "",
      Recipient_Details: {},
      SelectedworkingStyle: "",
      content: "",
      PdfFileName: "",
      DocFileName: "",
      CoverLetterName: "",
      UserPackageDetails: [],
      profilepicurl: "",
      profilepicbase64: "",
    };
  },
  mounted() {
    this.GetBindDataToTemplate();
  },
  methods: {
    async downloadFile(item,nameoffile) {
      // Replace 'YOUR_S3_FILE_URL' with your actual S3 file URL
      const s3FileURL = item;

      try {
        const response = await fetch(s3FileURL);
        const blob = await response.blob();
        debugger;
        // Extract the filename from the S3 URL
        const filename = nameoffile;

        // Create an <a> element and trigger the download
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([blob]));
        link.download = filename; // Set the filename for the downloaded file
        link.click();
      } catch (error) {
        console.error('Error downloading file:', error);
        // Handle the error as needed
      }
    },
    Profilepicbinding(val)
    {
      this.resumeobj.profilepicurl=val;
      this.profilepicbase64=val;
      localStorage.setItem("resumeobj", JSON.stringify(this.resumeobj));
      this.GetBindDataToTemplate();
    },
    GenerateorRegenerate() {
      debugger;
      if (
        this.resumeobj.jobdescription == "" ||
        this.resumeobj.jobdescription == null ||
        this.resumeobj.jobdescription == undefined
      ) {
        this.GetjobDescriptionNew();
      } else {
        this.GetRegeneratejobDescription();
      }
    },
    GenerateJD() {
      if (this.CheckPackageV3()) {
        this.GetRegJobDescription(
          this.WorkEXPRegenerateJD,
          this.EducationRegenerateJD,
          this.SkillRegenerateJD,
          this.JDRegenerateJD
        );
      }
    },
    Isjdallowed() {
      var self = this;
      if (self.PaidUser && self.jdaccess) {
        self.actionMsg =
          "Do you want to use 1 credit out of remaining " +
          self.remainingCoverLetterGenerate +
          " credit(s)?";
        self.delete_what = "Regenerate";
        self.showCnfrmPopUp = true;
      } else {
        this.$router.push({ path: "ChangePlan" });
      }
    },
    Stillregenerate() {
      var self = this;
      self.actionMsg =
        "Your CV and JD match is very poor. Proceeding further may distort your cover letter. Do you want to proceed?";
      self.delete_what = "Lowgenerate";
      self.showCnfrmPopUp = true;
    },
    Yesconfirm() {
      this.showCnfrmPopUp = false;
      if (this.delete_what == "Regenerate") {
        // this.ChangeJD();
        if (
          this.resumeobj.inputjobdescription != "" &&
          this.resumeobj.inputjobdescription != null &&
          this.resumeobj.inputjobdescription != undefined
        ) {
          this.GenerateorRegenerate();
        } else {
          $("#RegeneratejdsectionV2").modal("show");
        }
      }
      if (this.delete_what == "Lowgenerate") {
        debugger;
        this.desc = this.ext_desc;
        this.desclist.push(this.ext_desc);
        this.resumeobj.jobdescription = this.desc;
        this.resumeobj.opening = "";
        this.opening = "";
        this.resumeobj.letterbody = "";
        this.letterbody = "";
        localStorage.setItem("resumeobj", JSON.stringify(this.resumeobj));
        this.GetBindDataToTemplate();
      }
    },

    NO() {
      this.showCnfrmPopUp = false;
      if (this.delete_what == "Regenerate") {
        $("#descsec").modal("hide");
      }
    },
    closeloader() {
      var self = this;
      self.loader = false;
      self.finished = false;
    },
    GetJobDescription(val) {
      debugger;
      if (this.CheckPackageV3()) {
        var self = this;
        self.loadermsg = "Getting information";
        self.loader = true;
        var JD = "";
        var jobtitle = "";
        var degree = "";
        var skills = "";
        if (
          self.resumeobj.jobdescription != "" &&
          self.resumeobj.jobdescription != null
        ) {
          JD = self.resumeobj.jobdescription;
        }
        if (self.resumeobj.Job_Title != "") {
          jobtitle = self.resumeobj.Job_Title;
        }
        if (self.resumeobj.degree != "" && self.resumeobj.degree != null) {
          degree = self.resumeobj.degree;
        }
        if (self.resumeobj.skills != "" && self.resumeobj.skills != null) {
          skills = JSON.stringify(self.resumeobj.skills);
        }

        var data = {
          work_experience: jobtitle,
          education: degree,
          skills: skills,
          job_description: JD,
        };

        axios({
          method: "post",
          url: rest_service.PythonAPI + "/cv_to_customize_coverletter",
          // url: rest_service.PythonAPI +"/v2/customize_cl_api",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(function (response) {
            debugger;
            if (response.data) {
              self.desclist = [];
              self.desclist[0] = response.data.content;
              if (val == "New") {
                self.desc = self.desclist[0];
                self.resumeobj.jobdescription = self.desc;
                localStorage.setItem(
                  "resumeobj",
                  JSON.stringify(self.resumeobj)
                );
              }
              self.updatelimit("cover", self.usedCoverLetterGenerate + 1);
              self.GetBindDataToTemplate();
              self.loadermsg = "Done";
              self.finished = true;
              setTimeout((xy) => {
                self.closeloader();
              }, 2000);
            } else {
              setTimeout((xy) => {
                self.closeloader();
              }, 2000);
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
            return error;
          });
      }
    },
    GetRegJobDescription(work_experience, education, skills, job_description) {
      debugger;
      var self = this;
      self.loadermsg = "Regenerating job description";
      self.loader = true;
      var data = {
        work_experience: work_experience,
        education: education,
        skills: skills,
        job_description: job_description,
      };
      axios({
        method: "post",
        url: rest_service.PythonAPI + "/cv_to_customize_coverletter",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(function (response) {
          debugger;
          if (response.data) {
            self.desclist.push(response.data.content);
            self.desc = response.data.content;
            self.updatelimit("cover", self.usedCoverLetterGenerate + 1);
            $("#Regeneratejdsection").modal("hide");
            self.loadermsg = "Done";
            self.finished = true;
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
          } else {
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          setTimeout((xy) => {
            self.closeloader();
          }, 2000);
          return error;
        });
    },
    GetjobDescriptionNew() {
      debugger;
      if (this.CheckPackageV3()) {
        var self = this;
        $("#RegeneratejdsectionV2").modal("hide");
        self.loadermsg = "Generating cover letter";
        self.loader = true;
        var desired_job_title = "";
        var desired_company = "";
        var strength = "";
        var skills = "";
        var experience_in_years = "";
        var recent_job_title = "";
        var work_style = "";
        var job_description = "";
        if (
          self.resumeobj.Recipient_Details != "" &&
          self.resumeobj.Recipient_Details != null &&
          self.resumeobj.Recipient_Details != undefined
        ) {
          if (
            self.resumeobj.Recipient_Details.Recipient_Company != "" &&
            self.resumeobj.Recipient_Details.Recipient_Company != null &&
            self.resumeobj.Recipient_Details.Recipient_Company != undefined
          ) {
            desired_company =
              self.resumeobj.Recipient_Details.Recipient_Company;
          }
          if (
            self.resumeobj.Recipient_Details.Postion != "" &&
            self.resumeobj.Recipient_Details.Postion != null &&
            self.resumeobj.Recipient_Details.Postion != undefined
          ) {
            desired_job_title = self.resumeobj.Recipient_Details.Postion;
          }
        }
        if (
          self.resumeobj.strengths != "" &&
          self.resumeobj.strengths != null &&
          self.resumeobj.strengths != undefined
        ) {
          strength = JSON.stringify(self.resumeobj.strengths);
        }
        if (
          self.resumeobj.skills != "" &&
          self.resumeobj.skills != null &&
          self.resumeobj.skills != undefined
        ) {
          skills = JSON.stringify(self.resumeobj.skills);
        }
        if (
          self.resumeobj.Experience != "" &&
          self.resumeobj.Experience != null &&
          self.resumeobj.Experience != undefined
        ) {
          experience_in_years = self.resumeobj.Experience;
        }
        if (
          self.resumeobj.Job_Title != "" &&
          self.resumeobj.Job_Title != null &&
          self.resumeobj.Job_Title != undefined
        ) {
          recent_job_title = self.resumeobj.Job_Title;
        }
        if (
          self.resumeobj.Workingstyle != "" &&
          self.resumeobj.Workingstyle != null &&
          self.resumeobj.Workingstyle != undefined
        ) {
          work_style = self.resumeobj.Workingstyle;
        }
        if (
          self.resumeobj.inputjobdescription != "" &&
          self.resumeobj.inputjobdescription != null &&
          self.resumeobj.inputjobdescription != undefined
        ) {
          job_description = self.resumeobj.inputjobdescription;
        }
        var data = {
          desired_job_title: desired_job_title,
          desired_company: desired_company,
          strength: strength,
          skills: skills,
          experience_in_years: experience_in_years,
          recent_job_title: recent_job_title,
          career_gap_reason: "None",
          work_style: work_style,
          job_description: job_description,
        };
        axios({
          method: "post",
          url: rest_service.PythonAPI + "/v2/customize_cl_api",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: data,
        })
          .then(function (response) {
            debugger;
            if (response.data) {
              var Data = response.data;
              const sanitizedJSONString = Data.content.replace(
                /[\x00-\x1F\x7F-\x9F]/g,
                ""
              );
              var content = JSON.parse(sanitizedJSONString);
              //var content = JSON.parse(response.data.content);
              var percent;
              var match = false;
              if (content.match_percentage != "") {
                percent = parseInt(content.match_percentage);
              }
              if (percent >= 80) {
                match = true;
              } else {
                match = false;
              }
              if (match) {
                self.desclist.push(content.cover_letter_body);
                self.desc = content.cover_letter_body;
                self.resumeobj.jobdescription = self.desc;
                self.resumeobj.opening = "";
                self.opening = "";
                self.resumeobj.letterbody = "";
                self.letterbody = "";
                localStorage.setItem(
                  "resumeobj",
                  JSON.stringify(self.resumeobj)
                );
                self.GetBindDataToTemplate();
              } else {
                self.ext_desc = content.cover_letter_body;
              }
              self.updatelimit("cover", self.usedCoverLetterGenerate + 1);

              $("#Regeneratejdsection").modal("hide");
              self.loadermsg = "Done";
              self.finished = true;
              setTimeout((xy) => {
                self.closeloader();
              }, 2000);
              if (match == false) self.Stillregenerate();
            } else {
              setTimeout((xy) => {
                self.closeloader();
              }, 2000);
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
            return error;
          });
      }
    },
    GetRegeneratejobDescription() {
      debugger;
      var self = this;
      $("#RegeneratejdsectionV2").modal("hide");
      self.loadermsg = "Generating cover letter...";
      self.loader = true;
      var cover_letter_body = "";
      var data = {
        cover_letter_body: self.resumeobj.jobdescription,
      };
      axios({
        method: "post",
        url: rest_service.PythonAPI + "/regenerate_coverletter",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then(function (response) {
          debugger;
          if (response.data) {
            var Data = response.data;
            const sanitizedJSONString = Data.content.replace(
              /[\x00-\x1F\x7F-\x9F]/g,
              ""
            );
            var content = JSON.parse(sanitizedJSONString);
            // var content = JSON.parse(response.data.content);
            self.desclist.push(content.coverletter_body);
            self.desc = content.coverletter_body;
            self.resumeobj.opening = "";
            self.opening = "";
            self.resumeobj.letterbody = "";
            self.letterbody = "";
            self.resumeobj.jobdescription = self.desc;
            localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
            self.updatelimit("cover", self.usedCoverLetterGenerate + 1);
            self.GetBindDataToTemplate();
            $("#Regeneratejdsection").modal("hide");
            self.loadermsg = "Done";
            self.finished = true;
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
          } else {
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          setTimeout((xy) => {
            self.closeloader();
          }, 2000);
          return error;
        });
    },
    // CheckPackage() {
    //   var self = this;
    //   var uid;
    //   if (localStorage.getItem("loggedinuserobj")) {
    //     uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
    //   }
    //   if (localStorage.getItem("UserPackageDetails")) {
    //     self.UserPackageDetails = JSON.parse(
    //       localStorage.getItem("UserPackageDetails")
    //     );
    //     if (self.UserPackageDetails == null || self.UserPackageDetails == "") {
    //       // this.$router.push({ path: "BuyPackage?email=" + uid.user.email });
    //       this.$router.push({ path: "ChangePlan" });
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   }
    // },

    CheckPackage() {
      var self = this;
      var access = false;
      var Packinfo = [];
      if (localStorage.getItem("UserPackageDetails")) {
        Packinfo = JSON.parse(localStorage.getItem("UserPackageDetails"));
        if (Packinfo == null || Packinfo == "" || Packinfo == undefined) {
          self.$router.push({ path: "ChangePlan" });
          return false;
        } else {
          Packinfo.forEach((element) => {
            if (element.coverLetter) {
              access = true;
              return;
            }
          });
          if (access == true) {
            return true;
          } else {
            self.$router.push({ path: "ChangePlan" });
            return false;
          }
        }
      } else {
        self.$router.push({ path: "ChangePlan" });
        return false;
      }
    },
    CheckPackageV2() {
      var self = this;
      var access = false;
      var Packinfo = [];
      if (localStorage.getItem("UserPackageDetails")) {
        Packinfo = JSON.parse(localStorage.getItem("UserPackageDetails"));
        if (Packinfo == null || Packinfo == "" || Packinfo == undefined) {
          return false;
        } else {
          Packinfo.forEach((element) => {
            if (element.coverLetter) {
              access = true;
              return;
            }
          });
          if (access == true) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    CheckPackageV3() {
      var self = this;
      var access = false;
      var Packinfo = [];
      if (localStorage.getItem("UserPackageDetails")) {
        Packinfo = JSON.parse(localStorage.getItem("UserPackageDetails"));
        if (Packinfo == null || Packinfo == "" || Packinfo == undefined) {
          return false;
        } else {
          Packinfo.forEach((element) => {
            if (element.coverLetter) {
              if (
                parseInt(element.maxNoCoverLetterGenerate) >
                parseInt(element.usedNoCoverLetterGenerate)
              ) {
                self.usedCoverLetterGenerate = parseInt(
                  element.usedNoCoverLetterGenerate
                );
                self.remainingCoverLetterGenerate =
                  parseInt(element.maxNoCoverLetterGenerate) -
                  parseInt(element.usedNoCoverLetterGenerate);
                access = true;
                return;
              }
            }
          });
          if (access == true) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        return false;
      }
    },
    Delete(val) {
      var self = this;
      if (val == "Date") {
        self.CoverLetterDate = "";
        self.resumeobj.coverletterdate = "";
        self.SaveResume();
      }
      if (val == "Recipient") {
        var data = {
          Recipient_First_Name: "",
          Recipient_Last_Name: "",
          Recipient_Company: "",
          Recipient_City: "",
          Recipient_State: "",
          Recipient_Zip: "",
          Recipient_PhoneNumber: "",
          Recipient_Email: "",
        };
        self.Recipient_Details = data;
        self.resumeobj.Recipient_Details = data;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.SaveResume();
      }
      if (val == "Subject") {
        self.subject = "";
        self.resumeobj.subject = "";
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.SaveResume();
      }
      if (val == "Greeting") {
        self.greeting = "";
        self.resumeobj.greeting = self.greeting;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.SaveResume();
      }
      if (val == "Opening") {
        self.opening = "";
        self.resumeobj.opening = self.opening;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.SaveResume();
      }
      if (val == "Body") {
        self.letterbody = "";
        self.resumeobj.letterbody = self.letterbody;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.SaveResume();
      }
      if (val == "Calltoaction") {
        self.calltoaction = "";
        self.resumeobj.calltoaction = self.calltoaction;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.SaveResume();
      }
      if (val == "Closing") {
        self.closing = "";
        self.resumeobj.closing = self.closing;
        localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
        self.SaveResume();
      }
    },
    SendEmailCoverLetter() {
      var self = this;
      if (self.CheckPackage()) {
        var uid;
        if (localStorage.getItem("loggedinuserobj")) {
          uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
        }

        self.loadermsg = "Sending mail...";
        self.loader = true;
        axios({
          method: "post",
          url: rest_service.baseURL + "/CoverLetter/SendEmailCoverletter",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + self.authenticationToken,
          },
          params: {
            key: uid.user.email + "/" + self.PdfFileName,
            email: uid.user.email,
            Name:
              self.resumeobj.User_Profile.First_Name +
              " " +
              self.resumeobj.User_Profile.Last_Name,
          },
        })
          .then(function (response) {
            if (response.data.result) {
              self.showloader = false;
              if (response.data.result == "Success") {
                self.loadermsg = "Email sent successfully";
                self.finished = true;
                setTimeout((xy) => {
                  self.closeloader();
                }, 2000);
              } else {
                self.loadermsg = "Failed to send email";
                self.finished = true;
                setTimeout((xy) => {
                  self.closeloader();
                }, 2000);
              }
            } else {
              self.loadermsg = "Failed to send email";
              self.finished = true;
              setTimeout((xy) => {
                self.closeloader();
              }, 2000);
              return null;
            }
          })
          .catch(function (error) {
            self.loadermsg = "Failed to send email";
            self.finished = true;
            setTimeout((xy) => {
              self.closeloader();
            }, 2000);
            console.log(error);
            return error;
          });
      }
    },
    downloadCoverletterPDF() {
      var self = this;
      if (self.CheckPackage()) {
        var uid;
        if (localStorage.getItem("loggedinuserobj")) {
          uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
        }

        self.showloader = true;
        axios({
          method: "get",
          url:
            rest_service.baseURL +
            "/S3proxy/GetCVlink?key=" +
            uid.user.email +
            "/" +
            self.PdfFileName,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + self.authenticationToken,
          },
        })
          .then(function (response) {
            if (response.data.result) {
              self.showloader = false;
             // window.open(response.data.result, "_blank", "channelmode=1");
             self.downloadFile(response.data.result,self.PdfFileName);
            } else {
              self.showloader = false;
              return null;
            }
          })
          .catch(function (error) {
            self.showloader = false;
            console.log(error);
            return error;
          });
      }
    },
    PrintCoverletterPDF() {
      var self = this;
      if (self.CheckPackage()) {
        var uid;
        if (localStorage.getItem("loggedinuserobj")) {
          uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
        }

        self.showloader = true;
        axios({
          method: "get",
          url:
            rest_service.baseURL +
            "/S3proxy/GetCVlink?key=" +
            uid.user.email +
            "/" +
            self.PdfFileName,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + self.authenticationToken,
          },
        })
          .then(function (response) {
            if (response.data.result) {
              self.showloader = false;
              window.open(response.data.result, "_blank", "channelmode=1");
             //self.downloadFile(response.data.result,self.PdfFileName);
            } else {
              self.showloader = false;
              return null;
            }
          })
          .catch(function (error) {
            self.showloader = false;
            console.log(error);
            return error;
          });
      }
    },
    downloadCoverletterDoc() {
      var self = this;
      if (self.CheckPackage()) {
        var uid;
        if (localStorage.getItem("loggedinuserobj")) {
          uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
        }

        self.showloader = true;
        axios({
          method: "get",
          url:
            rest_service.baseURL +
            "/S3proxy/GetCVlink?key=" +
            uid.user.email +
            "/" +
            self.DocFileName,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + self.authenticationToken,
          },
        })
          .then(function (response) {
            if (response.data.result) {
              self.showloader = false;
              //window.open(response.data.result, "_blank", "channelmode=1");
              self.downloadFile(response.data.result,self.DocFileName);
            } else {
              self.showloader = false;
              return null;
            }
          })
          .catch(function (error) {
            self.showloader = false;
            console.log(error);
            return error;
          });
      }
    },
    Dashboard() {
      this.$router.push({ path: "dashboard" });
    },
    SaveUpdateButton() {
      var coverletterid = "";
      if (localStorage.getItem("coverletterid")) {
        coverletterid = localStorage.getItem("coverletterid");
      } else if (this.$route.query.coverletterid) {
        coverletterid = this.$route.query.coverletterid;
      }
      if (coverletterid != "" && coverletterid != null) {
        this.SaveorUpdate = "Update";
      } else {
        this.SaveorUpdate = "Save";
      }
    },
    updatelimit(name, number) {
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var file = {
        Uniqueid: localStorage.getItem("package_uniqueid_cl"),
        Name: name,
        No: number,
      };
      axios({
        method: "post",
        url: rest_service.baseURL + "/User/UpdateUsageBasedOnType",
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
      })
        .then(function (response) {
          if (response) {
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        })
        .finally(() => {
          this.Getlimit();
        });
    },
    Getlimit() {
      debugger;
      var self = this;
      var uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      axios({
        method: "get",
        url: rest_service.baseURL + "/User/GetUserActivePackage",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
        params: {
          email: uid.user.email,
        },
      })
        .then(function (response) {
          if (response.data) {
            var dataToStore = JSON.stringify(response.data.result);
            localStorage.setItem("UserPackageDetails", dataToStore);
            if (self.CheckPackageV3()) {
              self.jdaccess = true;
            } else {
              self.jdaccess = false;
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },
    PageCall() {
      debugger;
      if (this.resumeobj.workingstylebinded == false) {
        this.BindClosingGreetingSubject();
        this.GetCalltoActionWithWorkStyle("New");
        this.GetLetterBodieswithworkingstyle("New");
        this.GetOpeningsWithworkingstyle("New");
        // this.GetJobDescription("New");
        debugger;
        if (
          this.resumeobj.jobdescription != null &&
          this.resumeobj.jobdescription != "" &&
          this.resumeobj.jobdescription != undefined
        ) {
        } else {
          this.GetjobDescriptionNew();
        }
      } else {
        this.GetCalltoActionWithWorkStyle();
        this.GetLetterBodieswithworkingstyle();
        this.GetOpeningsWithworkingstyle();
      }
      this.GetBindDataToTemplate();
    },

    BindClosingGreetingSubject() {
      debugger;
      var self = this;
      if (
        self.resumeobj.coverletterdate == undefined ||
        self.resumeobj.coverletterdate == "" ||
        self.resumeobj.coverletterdate == null
      ) {
        self.resumeobj.coverletterdate = moment().format("MMMM DD, YYYY");
        self.coverletterdate = moment().format("MMMM DD, YYYY");
      }
      if (
        self.resumeobj.subject == undefined ||
        self.resumeobj.subject == "" ||
        self.resumeobj.subject == null
      ) {
        var item = self.subjectlist[0].replace(
          "[Job Title]",
          self.resumeobj.Job_Title
        );
        self.resumeobj.subject = item;
        self.subject = item;
      }
      if (
        self.resumeobj.greeting == undefined ||
        self.resumeobj.greeting == "" ||
        self.resumeobj.greeting == null
      ) {
        self.resumeobj.greeting = self.greetinglist[2];
        self.greeting = self.greetinglist[2];
      }
      if (
        self.resumeobj.closing == undefined ||
        self.resumeobj.closing == "" ||
        self.resumeobj.closing == null
      ) {
        var item = self.closinglist[0];

        if (
          self.resumeobj.User_Profile.First_Name != undefined &&
          self.resumeobj.User_Profile.First_Name != ""
        ) {
          item = item.replace(
            "[Your First Name]",
            self.resumeobj.User_Profile.First_Name
          );
          if (
            self.resumeobj.User_Profile.Last_Name != undefined &&
            self.resumeobj.User_Profile.Last_Name != ""
          ) {
            item = item.replace(
              "[Your Last Name]",
              " " + self.resumeobj.User_Profile.Last_Name
            );
          }
        }
        self.resumeobj.closing = item;
        self.closing = item;
      }
    },
    GetCoverLetter(coverletterid) {
      var self = this;
      self.loadermsg = "Loading...";
      self.loader = true;
      axios({
        method: "get",
        url:
          rest_service.baseURL +
          "/CoverLetter/GetCoverLetterbyid?coverletterid=" +
          coverletterid,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + self.authenticationToken,
        },
      })
        .then(function (response) {
          if (response.data.result) {
            var coverletterobject = response.data.result;
            self.APIorLocaStorageBindData(coverletterobject);
            self.PageCall();
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        })
        .finally();
      {
        setTimeout((xy) => {
          self.finished = true;
          self.loadermsg = "Done";
          setTimeout((xy) => {
            self.closeloader();
          }, 2000);
        }, 2000);
      }
    },
    APIorLocaStorageBindData(coverletterobject) {
      debugger;
      var self = this;
      if (
        coverletterobject.userprofile != null &&
        coverletterobject.userprofile != undefined
      ) {
        self.resumeobj.User_Profile = JSON.parse(coverletterobject.userprofile);
        if (
          self.resumeobj.User_Profile != null &&
          self.resumeobj.User_Profile != undefined
        ) {
          self.First_Name = self.resumeobj.User_Profile.First_Name;
          self.Last_Name = self.resumeobj.User_Profile.Last_Name;
          self.Profession = self.resumeobj.User_Profile.Profession;
          self.City = self.resumeobj.User_Profile.City;
          self.State = self.resumeobj.User_Profile.State;
          self.Zip = self.resumeobj.User_Profile.Zip;
          self.PhoneNumber = self.resumeobj.User_Profile.PhoneNumber;
          self.Emaill = self.resumeobj.User_Profile.Emaill;
        }
      }
      if (
        coverletterobject.recipientDetails != null &&
        coverletterobject.recipientDetails != undefined
      ) {
        self.resumeobj.Recipient_Details = JSON.parse(
          coverletterobject.recipientDetails
        );
        if (
          self.resumeobj.Recipient_Details != null &&
          self.resumeobj.Recipient_Details != undefined
        ) {
          self.Recipient_First_Name = self.resumeobj.Recipient_First_Name;
          self.Recipient_Last_Name = self.resumeobj.Recipient_Last_Name;
          self.Recipient_Company = self.resumeobj.Recipient_Company;
          self.Recipient_City = self.resumeobj.Recipient_City;
          self.Recipient_State = self.resumeobj.Recipient_State;
          self.Recipient_Zip = self.resumeobj.Recipient_Zip;
          self.Recipient_PhoneNumber = self.resumeobj.Recipient_PhoneNumber;
          self.Recipient_Email = self.resumeobj.Recipient_Email;
        }
      }
      self.resumeobj.strengths = JSON.parse(coverletterobject.strengths);

      self.resumeobj.ResumeTemplate = coverletterobject.template;
      self.ResumeTemplate = coverletterobject.template;

      self.resumeobj.subject = coverletterobject.subject;
      self.subject = coverletterobject.subject;

      self.resumeobj.greeting = coverletterobject.greeting;
      self.greeting = coverletterobject.greeting;

      self.resumeobj.opening = coverletterobject.opening;
      self.opening = coverletterobject.opening;

      self.resumeobj.letterbody = coverletterobject.letterBody;
      self.letterbody = coverletterobject.letterBody;

      self.resumeobj.calltoaction = coverletterobject.callToAction;
      self.calltoaction = coverletterobject.callToAction;

      self.resumeobj.closing = coverletterobject.closing;
      self.closing = coverletterobject.closing;

      self.resumeobj.coverletterdate = coverletterobject.coverLetterDate;
      self.coverletterdate = coverletterobject.coverLetterDate;

      self.resumeobj.Job_Title = coverletterobject.jobTitle;
      self.resumeobj.Job_Title = coverletterobject.jobTitle;

      self.resumeobj.Experience = coverletterobject.experience;

      self.resumeobj.working_style = coverletterobject.workingStyle;
      self.working_style = coverletterobject.workingStyle;
      self.SelectedworkingStyle = coverletterobject.workingStyle;
      self.resumeobj.workingstylebinded = coverletterobject.workingstylebinded;

      self.desc = coverletterobject.jobdescription;
      self.resumeobj.jobdescription = coverletterobject.jobdescription;
      if (
        coverletterobject.jobdescription != "" &&
        coverletterobject.jobdescription != null &&
        coverletterobject.jobdescription != undefined
      ) {
        self.desclist.push(coverletterobject.jobdescription);
      }

      self.resumeobj.skills = JSON.parse(coverletterobject.skills);

      self.DocFileName = coverletterobject.docFileName;
      self.PdfFileName = coverletterobject.pdfFileName;
      debugger;
      if (
        coverletterobject.profilepicurl != null &&
        coverletterobject.profilepicurl != ""
      ) {
        self.GetProfilrpic(coverletterobject.profilepicurl);
        // self.resumeobj.coverletterobject;
      }
      self.resumeobj.inputjobdescription =
        coverletterobject.inputjobdescription;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
    },
    GetProfilrpic(id) {
      debugger;
      var self = this;
      axios({
        method: "get",
        url: rest_service.baseURL + "/S3proxy/GetAllImagebase64",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + self.authenticationToken,
        },
        responseType: "blob",
        params: {
          id: id,
        },
      })
        .then(function (respo) {
          if (respo) {
            var reader = new FileReader();
            reader.onload = () => {
              const base64String = reader.result;
              console.log(base64String);
              // response.data.result.profilePic = base64String;
              self.profilepicbase64 = base64String;
              self.resumeobj.profilepicurl = self.profilepicbase64;
              localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
              self.GetBindDataToTemplate();
            };
            reader.readAsDataURL(respo.data);
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    },

    Update() {
      debugger;
      var self = this;
      // self.showloader = true;
      self.loadermsg = "Saving...";
      self.loader = true;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      var packId=null;
      if(localStorage.getItem("package_uniqueid_cl")){
        packId = localStorage.getItem("package_uniqueid_cl");
      }
      var coverletterid = "";
      if (localStorage.getItem("coverletterid")) {
        coverletterid = localStorage.getItem("coverletterid");
      } else if (self.$route.query.coverletterid) {
        coverletterid = self.$route.query.coverletterid;
      }
      if (coverletterid != null && coverletterid != "") {
        self.GetContent();

        var data = {
          Content: self.content,
          userprofile: JSON.stringify(self.resumeobj.User_Profile),
          recipientDetails: JSON.stringify(self.resumeobj.Recipient_Details),
          CoverLetterDate: self.resumeobj.coverletterdate,
          subject: self.resumeobj.subject,
          greeting: self.resumeobj.greeting,
          opening: self.resumeobj.opening,
          letterBody: self.resumeobj.letterbody,
          callToAction: self.resumeobj.calltoaction,
          closing: self.resumeobj.closing,
          template: self.resumeobj.ResumeTemplate,
          strengths: JSON.stringify(self.resumeobj.strengths),
          jobTitle: self.resumeobj.Job_Title,
          experience: self.resumeobj.Experience,
          WorkingStyle: self.resumeobj.Workingstyle,
          workingstylebinded: true,
          SpecificJob: self.resumeobj.specificjob,
          userUniqueId: uid.user.uniqueId,
          UniqueId: coverletterid,
          Email: uid.user.email,
          Jobdescription: self.resumeobj.jobdescription,
          Inputjobdescription: self.resumeobj.inputjobdescription,
          PdfFileName:
            self.resumeobj.User_Profile.First_Name +
            "_" +
            self.resumeobj.User_Profile.Last_Name +
            "_",
          DocFileName:
            self.resumeobj.User_Profile.First_Name +
            "_" +
            self.resumeobj.User_Profile.Last_Name +
            "_",
          Profilepicbase64: self.profilepicbase64,
        };

        axios({
          method: "post",
          url: rest_service.baseURL + "/CoverLetter/UpdateCoverLetter",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            package_uniqueid: packId,
          },
          data,
        })
          .then(function (response) {
            self.showloader = false;
            if (response.status == "200") {
              var response = response.data.result;
              if (response.success == true) {
                if (response.coverletterinfo) {
                  self.DocFileName = response.coverletterinfo.docFileName;
                  self.PdfFileName = response.coverletterinfo.pdfFileName;
                  self.resumeobj.docFileName =
                    response.coverletterinfo.docFileName;
                  self.resumeobj.pdfFileName =
                    response.coverletterinfo.pdfFileName;
                  localStorage.setItem(
                    "resumeobj",
                    JSON.stringify(self.resumeobj)
                  );
                }
                self.loadermsg = "Saved";
                self.finished = true;
                setTimeout((xy) => {
                  self.closeloader();
                }, 3000);
                // self.sucessmsgtext = "Saved successfully";
                // self.isdisplaymsg = true;
                // self.iserrormsg = false;
                // setTimeout((xy) => {
                //   self.closeMessage();
                // }, 5000);
              } else {
                setTimeout((xy) => {
                  self.closeloader();
                }, 3000);
                return null;
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            setTimeout((xy) => {
              self.closeloader();
            }, 3000);
            return error;
          });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
    },
    GetContent() {
      debugger;
      var self = this;

      if (self.ResumeTemplate == "CoverLetter_1") {
        self.content = self.$refs.Template1.GetContent();
        self.profilepicbase64 = self.$refs.Template1.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_2") {
        self.content = self.$refs.Template2.GetContent();
        self.profilepicbase64 = self.$refs.Template2.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_3") {
        self.content = self.$refs.Template3.GetContent();
        self.profilepicbase64 = self.$refs.Template3.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_4") {
        self.content = self.$refs.Template4.GetContent();
        self.profilepicbase64 = self.$refs.Template4.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_5") {
        self.content = self.$refs.Template5.GetContent();
        self.profilepicbase64 = self.$refs.Template5.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_6") {
        self.content = self.$refs.Template6.GetContent();
        self.profilepicbase64 = self.$refs.Template6.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_7") {
        self.content = self.$refs.Template7.GetContent();
        self.profilepicbase64 = self.$refs.Template7.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_8") {
        self.content = self.$refs.Template8.GetContent();
        self.profilepicbase64 = self.$refs.Template8.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_9") {
        self.content = self.$refs.Template9.GetContent();
        self.profilepicbase64 = self.$refs.Template9.Getprofilepic();
      }
      if (self.ResumeTemplate == "CoverLetter_10") {
        self.content = self.$refs.Template10.GetContent();
        self.profilepicbase64 = self.$refs.Template10.Getprofilepic();
      }
    },
    Save() {
      var self = this;
      if (self.CheckPackage()) {
        self.loadermsg = "Saving";
        self.loader = true;
        var uid;
        if (localStorage.getItem("loggedinuserobj")) {
          uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
        }

        self.GetContent();
        var data = {
          Content: self.content,
          userprofile: JSON.stringify(self.resumeobj.User_Profile),
          recipientDetails: JSON.stringify(self.resumeobj.Recipient_Details),
          CoverLetterDate: self.resumeobj.coverletterdate,
          subject: self.resumeobj.subject,
          greeting: self.resumeobj.greeting,
          opening: self.resumeobj.opening,
          letterBody: self.resumeobj.letterbody,
          callToAction: self.resumeobj.calltoaction,
          closing: self.resumeobj.closing,
          template: self.resumeobj.ResumeTemplate,
          strengths: JSON.stringify(self.resumeobj.strengths),
          jobTitle: self.resumeobj.Job_Title,
          experience: self.resumeobj.Experience,
          WorkingStyle: self.resumeobj.Workingstyle,
          workingstylebinded: true,
          Jobdescription: self.resumeobj.jobdescription,
          Inputjobdescription: self.resumeobj.inputjobdescription,
          Skills: JSON.stringify(self.resumeobj.skills),
          SpecificJob: self.resumeobj.specificjob,
          userUniqueId: uid.user.uniqueId,
          Email: uid.user.email,
          PdfFileName:
            self.resumeobj.User_Profile.First_Name +
            "_" +
            self.resumeobj.User_Profile.Last_Name +
            "_",
          DocFileName:
            self.resumeobj.User_Profile.First_Name +
            "_" +
            self.resumeobj.User_Profile.Last_Name +
            "_",
        };

        axios({
          method: "post",
          url: rest_service.baseURL + "/CoverLetter/SaveCoverLetter",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            package_uniqueid: localStorage.getItem("package_uniqueid_cl"),
          },
          data,
        })
          .then(function (response) {
            debugger;
            self.showloader = false;
            if (response.status == "200") {
              var response = response.data.result;
              if (response.success == true) {
                if (response.coverletterinfo) {
                  self.DocFileName = response.coverletterinfo.docFileName;
                  self.PdfFileName = response.coverletterinfo.pdfFileName;
                  self.resumeobj.docFileName =
                    response.coverletterinfo.docFileName;
                  self.resumeobj.pdfFileName =
                    response.coverletterinfo.pdfFileName;
                  localStorage.setItem(
                    "resumeobj",
                    JSON.stringify(self.resumeobj)
                  );
                }
                localStorage.setItem("coverletterid", response.coverletterid);
                self.SaveUpdateButton();
                self.loadermsg = "Saved";
                self.finished = true;
                setTimeout((xy) => {
                  self.closeloader();
                }, 3000);
                // self.sucessmsgtext = "Saved successfully";
                // self.isdisplaymsg = true;
                // self.iserrormsg = false;
                // setTimeout((xy) => {
                //   self.closeMessage();
                // }, 5000);
              }
            } else {
              setTimeout((xy) => {
                self.closeloader();
              }, 30000);
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            setTimeout((xy) => {
              self.closeloader();
            }, 30000);
            return error;
          });
      }
    },
    Selecttemplate(val) {
      var self = this;
      self.ResumeTemplate = val;
      self.resumeobj.ResumeTemplate = self.ResumeTemplate;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#Templatesection").modal("hide");
    },
    ChangeTemplate() {
      $("#Templatesection").modal("show");
    },
    ChangeJD() {
      if (this.CheckPackage()) {
        var self = this;
        if (self.resumeobj.skills != "" && self.resumeobj.skills != null) {
          self.SkillRegenerateJD = self.resumeobj.skills.join(", ");
        }
        if (self.resumeobj.Job_Title != "") {
          self.WorkEXPRegenerateJD = self.resumeobj.Job_Title;
        }
        if (self.resumeobj.degree != "" && self.resumeobj.degree != null) {
          self.EducationRegenerateJD = self.resumeobj.degree;
        }
        $("#Regeneratejdsection").modal("show");
      } else {
        $("#descsec").modal("hide");
      }
    },
    SaveWorkStyle() {
      var self = this;
      self.resumeobj.Workingstyle = self.SelectedworkingStyle;
      self.GetCalltoActionWithWorkStyle("New");
      self.GetLetterBodieswithworkingstyle("New");
      self.GetOpeningsWithworkingstyle("New");
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      $("#Working_Style").modal("hide");
    },
    SelectWorkStyle(item) {
      this.SelectedworkingStyle = item;
    },
    Workingstyleselect() {
      $("#Working_Style").modal("show");
    },
    GetClosing() {
      var self = this;
      if (
        self.resumeobj.Job_Title != undefined &&
        self.resumeobj.Job_Title != ""
      ) {
        self.strengths = [];
        axios({
          method: "get",
          url: rest_service.PythonAPI + "/search_cl_closings",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            job_title: self.Job_Title,
          },
        })
          .then(function (response) {
            if (response.data) {
              response.data.forEach((e) => {
                self.closinglist.push(e);
              });
            } else {
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);

            return error;
          });
      }
    },
    GetCalltoAction() {
      var self = this;
      if (
        self.resumeobj.Job_Title != undefined &&
        self.resumeobj.Job_Title != ""
      ) {
        self.strengths = [];
        axios({
          method: "get",
          url: rest_service.PythonAPI + "/search_cl_bodies",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            job_title: self.Job_Title,
          },
        })
          .then(function (response) {
            if (response.data) {
              response.data.forEach((e) => {
                self.calltoactionlist.push(e);
              });
            } else {
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);

            return error;
          });
      }
    },
    GetLetterBodies() {
      var self = this;
      if (
        self.resumeobj.Job_Title != undefined &&
        self.resumeobj.Job_Title != ""
      ) {
        self.strengths = [];
        axios({
          method: "get",
          url: rest_service.PythonAPI + "/search_cl_bodies",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            job_title: self.Job_Title,
          },
        })
          .then(function (response) {
            if (response.data) {
              response.data.forEach((e) => {
                self.bodieslist.push(e);
              });
            } else {
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);

            return error;
          });
      }
    },
    GetOpenings() {
      var self = this;
      if (
        self.resumeobj.Job_Title != undefined &&
        self.resumeobj.Job_Title != ""
      ) {
        self.strengths = [];
        axios({
          method: "get",
          url: rest_service.PythonAPI + "/search_cl_openings",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            job_title: self.Job_Title,
          },
        })
          .then(function (response) {
            if (response.data) {
              response.data.forEach((e) => {
                self.openinglist.push(e);
              });
            } else {
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);

            return error;
          });
      }
    },
    extractText(html) {
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = html;
      const formattedHTML = tempDiv.textContent;
      return formattedHTML;
    },
    EditPopup(val) {
      var self = this;

      if (val == "NameCon") {
        $("#NameCon").modal("show");
      }
      if (val == "Date") {
        self.coverletterdate = self.resumeobj.coverletterdate;
        $("#Date").modal("show");
      }
      if (val == "Recipient") {
        $("#Recipient").modal("show");
      }
      if (val == "Subject") {
        $("#Subject").modal("show");
      }
      if (val == "Greeting") {
        $("#Greeting").modal("show");
      }
      if (val == "Opening") {
        // self.GetOpenings();
        $("#Opening").modal("show");
      }
      if (val == "Body") {
        // self.GetLetterBodies();
        $("#letterbody").modal("show");
      }
      if (val == "Desc") {
        // self.GetLetterBodies();
        $("#descsec").modal("show");
      }
      if (val == "Calltoaction") {
        // self.GetCalltoAction();
        $("#Calltoaction").modal("show");
      }
      if (val == "Closing") {
        // self.GetClosing();
        $("#Closing").modal("show");
      }
      if (val == "inputjd") {
        // self.GetClosing();
        $("#inputjd").modal("show");
      }
    },
    SaveNameCon() {
      var self = this;
      self.resumeobj.User_Profile.Email = self.Email;
      self.resumeobj.User_Profile.PhoneNumber = self.PhoneNumber;
      self.resumeobj.User_Profile.Zip = self.Zip;
      self.resumeobj.User_Profile.State = self.State;
      self.resumeobj.User_Profile.City = self.City;
      self.resumeobj.User_Profile.Profession = self.Profession;
      self.resumeobj.User_Profile.First_Name = self.First_Name;
      self.resumeobj.User_Profile.Last_Name = self.Last_Name;

      // var data = {
      //   Email: self.Email,
      //   PhoneNumber: self.PhoneNumber,
      //   Zip: self.Zip,
      //   State: self.State,
      //   City: self.City,
      //   Profession: self.Profession,
      //   First_Name: self.First_Name,
      //   Last_Name: self.Last_Name,
      // };
      // self.resumeobj.User_Profile = data;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#NameCon").modal("hide");
    },
    SaveDate() {
      this.resumeobj.coverletterdate = this.coverletterdate;
      this.SaveResume();
      $("#Date").modal("hide");
    },
    SaveRecipient() {
      var self = this;
      var data = {
        Recipient_First_Name: self.Recipient_First_Name,
        Recipient_Last_Name: self.Recipient_Last_Name,
        Recipient_Company: self.Recipient_Company,
        Recipient_City: self.Recipient_City,
        Recipient_State: self.Recipient_State,
        Recipient_Zip: self.Recipient_Zip,
        Recipient_PhoneNumber: self.Recipient_PhoneNumber,
        Recipient_Email: self.Recipient_Email,
      };
      self.Recipient_Details = data;
      self.resumeobj.Recipient_Details = data;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#Recipient").modal("hide");
    },
    Edit_Subject(item) {
      var self = this;
      item = item.replace("[Job Title]", self.resumeobj.Job_Title);
      // item=item.replace("[Date]",self.resumeobj.Date)
      self.subject = item;
    },

    SaveSubject() {
      var self = this;
      self.resumeobj.subject = self.subject;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#Subject").modal("hide");
    },

    Edit_Greeting(item) {
      var self = this;
      self.greeting = item;
    },
    SaveGreeting(item) {
      var self = this;
      self.resumeobj.greeting = self.greeting;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#Greeting").modal("hide");
    },
    Edit_Opening(item) {
      var self = this;
      if (self.opening.includes(item)) {
        // self.opeining =self.opeining+ item;
        self.opening = self.opening.replace(item, "").trim();
      } else {
        self.opening = self.opening + item;
      }
    },
    SaveOpening(item) {
      var self = this;
      self.resumeobj.opening = self.opening;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#Opening").modal("hide");
    },
    Edit_body(item) {
      var self = this;
      if (self.letterbody.includes(item)) {
        // self.opeining =self.opeining+ item;
        self.letterbody = self.letterbody.replace(item, "").trim();
      } else {
        self.letterbody = self.letterbody + item;
      }
    },
    Edit_dec(item) {
      var self = this;
      if (self.desc.includes(item)) {
        self.desc = self.desc.replace(item, "").trim();
      } else {
        self.desc = self.desc + item;
      }
    },
    SaveBody() {
      var self = this;
      self.resumeobj.letterbody = self.letterbody;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#letterbody").modal("hide");
    },
    Savedesc() {
      debugger;
      var self = this;
      self.resumeobj.jobdescription = self.desc;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#descsec").modal("hide");
    },

    Edit_Calltoaction(item) {
      var self = this;
      if (self.calltoaction.includes(item)) {
        // self.opeining =self.opeining+ item;
        self.calltoaction = self.calltoaction.replace(item, "").trim();
      } else {
        self.calltoaction = self.calltoaction + item;
      }
    },
    SaveCallToAction() {
      var self = this;
      self.resumeobj.calltoaction = self.calltoaction;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#Calltoaction").modal("hide");
    },
    Edit_Closing(item) {
      var self = this;
      if (
        self.resumeobj.User_Profile.First_Name != undefined &&
        self.resumeobj.User_Profile.First_Name != ""
      ) {
        item = item.replace(
          "[Your First Name]",
          self.resumeobj.User_Profile.First_Name
        );
        if (
          self.resumeobj.User_Profile.Last_Name != undefined &&
          self.resumeobj.User_Profile.Last_Name != ""
        ) {
          item = item.replace(
            "[Your Last Name]",
            " " + self.resumeobj.User_Profile.Last_Name
          );
        }
      }
      self.closing = item;
      // }
    },
    SaveClosing(item) {
      var self = this;
      self.resumeobj.closing = self.closing;
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
      self.SaveResume();
      $("#Closing").modal("hide");
    },
    SaveResume() {
      var self = this;
      self.GetBindDataToTemplate();
      localStorage.setItem("resumeobj", JSON.stringify(self.resumeobj));
    },
    GetResumeData() {},
    GetBindDataToTemplate() {
      debugger;
      var self = this;
      self.showloader = true;
      self.Email = self.resumeobj.User_Profile.Email;
      self.PhoneNumber == self.resumeobj.User_Profile.PhoneNumber;
      self.Zip = self.resumeobj.User_Profile.Zip;
      self.State = self.resumeobj.User_Profile.State;
      self.City = self.resumeobj.User_Profile.City;
      self.Profession = self.resumeobj.User_Profile.Profession;
      self.First_Name = self.resumeobj.User_Profile.First_Name;
      self.Last_Name = self.resumeobj.User_Profile.Last_Name;
      self.PhoneNumber = self.resumeobj.User_Profile.PhoneNumber;
      self.ResumeTemplate = self.resumeobj.ResumeTemplate;
      self.Recipient_Details = self.resumeobj.Recipient_Details;
      if (self.Recipient_Details != null) {
        self.Recipient_First_Name = self.Recipient_Details.Recipient_First_Name;
        self.Recipient_Last_Name = self.Recipient_Details.Recipient_Last_Name;
        self.Recipient_Company = self.Recipient_Details.Recipient_Company;
        self.Recipient_City = self.Recipient_Details.Recipient_City;
        self.Recipient_State = self.Recipient_Details.Recipient_State;
        self.Recipient_Zip = self.Recipient_Details.Recipient_Zip;
        self.Recipient_PhoneNumber =
          self.Recipient_Details.Recipient_PhoneNumber;
        self.Recipient_Email = self.Recipient_Details.Recipient_Email;
      }
      if (self.resumeobj.subject != undefined && self.resumeobj.subject != "") {
        self.subject = self.resumeobj.subject;
      }
      if (
        self.resumeobj.greeting != undefined &&
        self.resumeobj.greeting != ""
      ) {
        self.greeting = self.resumeobj.greeting;
      }

      if (self.resumeobj.opening != undefined && self.resumeobj.opening != "") {
        self.opening = self.resumeobj.opening;
      }
      if (
        self.resumeobj.letterbody != undefined &&
        self.resumeobj.letterbody != ""
      ) {
        self.letterbody = self.resumeobj.letterbody;
      }
      if (
        self.resumeobj.calltoaction != undefined &&
        self.resumeobj.calltoaction != ""
      ) {
        self.calltoaction = self.resumeobj.calltoaction;
      }
      if (self.resumeobj.closing != undefined && self.resumeobj.closing != "") {
        self.closing = self.resumeobj.closing;
      }
      if (
        self.resumeobj.jobdescription != undefined &&
        self.resumeobj.jobdescription != ""
      ) {
        self.desc = self.resumeobj.jobdescription;
      }

      if (self.ResumeTemplate == "CoverLetter_1") {
        self.$refs.Template1.Bindfortemplates(self.resumeobj);
        self.$refs.Template1_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_2") {
        self.$refs.Template2.Bindfortemplates(self.resumeobj);
        self.$refs.Template2_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_3") {
        self.$refs.Template3.Bindfortemplates(self.resumeobj);
        self.$refs.Template3_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_4") {
        self.$refs.Template4.Bindfortemplates(self.resumeobj);
        self.$refs.Template4_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_5") {
        self.$refs.Template5.Bindfortemplates(self.resumeobj);
        self.$refs.Template5_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_6") {
        self.$refs.Template6.Bindfortemplates(self.resumeobj);
        self.$refs.Template6_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_7") {
        self.$refs.Template7.Bindfortemplates(self.resumeobj);
        self.$refs.Template7_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_8") {
        self.$refs.Template8.Bindfortemplates(self.resumeobj);
        self.$refs.Template8_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_9") {
        self.$refs.Template9.Bindfortemplates(self.resumeobj);
        self.$refs.Template9_1.Bindfortemplates(self.resumeobj);
      }
      if (self.ResumeTemplate == "CoverLetter_10") {
        self.$refs.Template10.Bindfortemplates(self.resumeobj);
        self.$refs.Template10_1.Bindfortemplates(self.resumeobj);
      }
      self.showloader = false;
    },
    GetLetterBodieswithworkingstyle(val) {
      var self = this;
      self.showloader = true;
      if (
        self.resumeobj.Job_Title != undefined &&
        self.resumeobj.Job_Title != ""
      ) {
        self.strengths = [];
        axios({
          method: "get",
          url: rest_service.PythonAPI + "/search_cl_bodies",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            job_title: self.resumeobj.Job_Title,
            working_style: self.resumeobj.Workingstyle,
          },
        })
          .then(function (response) {
            self.showloader = false;
            if (response.data) {
              self.bodieslist = [];
              response.data.forEach((e) => {
                self.bodieslist.push(e);
              });
              if (val == "New" && self.PaidUser == false) {
                self.letterbody = self.bodieslist[0];
                self.resumeobj.letterbody = self.letterbody;
                localStorage.setItem(
                  "resumeobj",
                  JSON.stringify(self.resumeobj)
                );
              } else if (val == "New" && self.PaidUser == true) {
                self.letterbody = "";
                self.resumeobj.letterbody = self.letterbody;
                localStorage.setItem(
                  "resumeobj",
                  JSON.stringify(self.resumeobj)
                );
              }
              self.GetBindDataToTemplate();
            } else {
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            self.showloader = false;
            return error;
          });
      }
    },
    GetOpeningsWithworkingstyle(val) {
      var self = this;
      self.showloader = true;
      if (
        self.resumeobj.Job_Title != undefined &&
        self.resumeobj.Job_Title != ""
      ) {
        self.strengths = [];
        axios({
          method: "get",
          url: rest_service.PythonAPI + "/search_cl_openings",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            job_title: self.resumeobj.Job_Title,
            working_style: self.resumeobj.Workingstyle,
          },
        })
          .then(function (response) {
            self.showloader = false;
            if (response.data) {
              self.openinglist = [];
              response.data.forEach((e) => {
                self.openinglist.push(e);
              });
              if (val == "New" && self.PaidUser == false) {
                self.opening = self.openinglist[0];
                self.resumeobj.opening = self.opening;
                localStorage.setItem(
                  "resumeobj",
                  JSON.stringify(self.resumeobj)
                );
              } else if (val == "New" && self.PaidUser == true) {
                self.opening = "";
                self.resumeobj.opening = self.opening;
                localStorage.setItem(
                  "resumeobj",
                  JSON.stringify(self.resumeobj)
                );
              }
              self.GetBindDataToTemplate();
            } else {
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            self.showloader = false;
            return error;
          });
      }
    },
    GetCalltoActionWithWorkStyle(val) {
      var self = this;
      self.showloader = true;
      if (
        self.resumeobj.Job_Title != undefined &&
        self.resumeobj.Job_Title != ""
      ) {
        self.strengths = [];
        axios({
          method: "get",
          url: rest_service.PythonAPI + "/search_cl_call_to_action",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            job_title: self.resumeobj.Job_Title,
            working_style: self.resumeobj.Workingstyle,
          },
        })
          .then(function (response) {
            self.showloader = false;
            if (response.data) {
              self.calltoactionlist = [];
              response.data.forEach((e) => {
                self.calltoactionlist.push(e);
              });
              if (val == "New") {
                self.calltoaction = self.calltoactionlist[0];
                self.resumeobj.calltoaction = self.calltoaction;
                localStorage.setItem(
                  "resumeobj",
                  JSON.stringify(self.resumeobj)
                );
              }
              self.GetBindDataToTemplate();
            } else {
              return null;
            }
          })
          .catch(function (error) {
            console.log(error);
            self.showloader = false;
            return error;
          });
      }
    },
  },
};
</script>

<style>
.coverfinalletter {
  padding: 60px 0;
}
.coverfinalletter .main-container {
  box-shadow: 0 2px 4px #b4c9da;
  margin-bottom: 8px;
}
.coverfinalletter ul li h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0 0 10px;
}
.coverfinalletter ul li:first-child {
  padding-left: 0;
}
.coverfinalletter ul li {
  font-size: 15px;
  padding: 4px 0;
  padding-left: 8px;
}
.coverfinalletter ul li:hover {
  color: #1f85cd;
  cursor: pointer;
}
.coverfinalletter .right-ul li {
  padding-left: 0;
  display: flex;
  align-items: center;
}
.coverfinalletter .right-ul li a {
  display: flex;
  align-items: center;
}
.coverfinalletter .right-ul li i {
  margin-right: 5px;
  font-size: 18px;
}

.coverfinalletter .modal-content {
  border-radius: 0;
}
.coverfinalletter .modal-body {
  padding: 30px;
}
.bottom-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.button-box {
  border: 1px solid #d7dde2;
  border-radius: 26px;
  background: #fff;
  cursor: pointer;
  height: 135px;
  margin-bottom: 2.7rem;
  padding: 1rem;
  position: relative;
  user-select: none;
  -ms-user-select: none;
  text-align: center;
  font-size: 14px;
}
.button-box b {
  display: block;
  text-align: center;
  margin: 0 0 5px;
  font-size: 15px;
  text-transform: uppercase;
}
button.button-box.selected {
  border: 2px solid #1f85cd;
  color: #000;
  background: #f6f8fa;
}
.button-boxspe {
  border: 1px solid #d7dde2;
  border-radius: 30px;
  background: #fff;
  cursor: pointer;
  padding: 15px 1rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -ms-user-select: none;
  display: flex;
  text-align: center;
  justify-content: center;
  width: 160px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0 10px;
  color: #333;
  cursor: pointer;
}
button.button-boxspe:hover,
button.button-boxspe.selected {
  border-color: #1f85cd;
  color: #333;
  border-width: 2px;
  box-shadow: 0 0 3px rgba(58, 150, 240, 0.2);
  background-color: #f6f8fa;
}
.bottom-toolbar button {
  margin: 0 5px;
}
ul.right-ul + div {
  margin: 10px 0;
}
.overflow-scroll {
  overflow: auto;
  padding-right: 5px;
  max-height: 380px;
}
.coverfinalletter .listing-sec li {
  border: 1px solid #e6e5eb;
  padding: 16px !important;
  margin-bottom: 10px;
}
.listing-box {
  display: flex;
  align-items: center;
}
.listing-box span {
  background: #1f85cd;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 10px;
  min-width: 30px;
}
.listing-box span.red {
  background: #d04141;
  /* display: none; */
}
.coverfinalletter .listing-sec li.selected .listing-box span.red {
  display: flex;
}
.coverfinalletter .listing-sec li.selected .listing-box span.fa-plus {
  display: none;
}
.coverfinalletter .listing-sec li.selected {
  background: #f7f7f7;
  color: #000;
}
div#resumesec7 .left-container > div {
  margin: 0 10px !important;
}
#resumesec7 .img-box img {
  height: 70px !important;
  border: none !important;
}
#resumesec7 h1 {
  font-size: 28px !important;
}
#resumesec7 .img-box img {
  height: 80px !important;
  width: 80px !important;
  border: none !important;
}
#resumesec3 h1,
#resumesec4 h1,
#resumesec5 h1,
#resumesec6 h1,
#resumesec7 h1,
#resumesec8 h1,
#resumesec9 h1,
#resumesec10 h1 {
  font-size: 28px !important;
}
.coverfinalletter img#imgPreview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
}
.coverfinalletter .dropdown .dropdown-menu li a {
  color: #000;
}
.coverfinalletter .dropdown .dropdown-menu li a:hover {
  color: #187ac1;
}
textarea#w3review {
  margin: 10px auto;
  width: 100%;
}
</style>