<template>
  <div>
    <div id="resumesec">
      <body style="margin: 0; padding: 0">
        <div
          class="main-container"
          style="
            display: flex;
            height: 100%;
            font-size: 16px;
            color: #000;
            line-height: 1.3;
            font-family: Poppins, sans-serif;
          "
        >
          <div
            class="left-container blue-bg"
            style="width: 30%; color: #000; background: none; padding: 30px"
          >
            <div class="img-box" style="padding: 20px 0 0">
              <img
                v-if="resume.pic && resume.pic != '' && resume.pic != undefined"
                style="max-width: 150px; width: 100%; object-fit: cover"
                :src="resume.pic"
                alt
              />
              <img
                v-else
                style="max-width: 150px; width: 100%; object-fit: cover"
                src="https://pinnacle.works/wp-content/uploads/2022/06/dummy-image.jpg"
                alt
              />
            </div>
            <div v-if="resume.summary" style="margin-top: 20px">
              <div style="font-size: 18px; text-align: center">About Me</div>
              <div>
                <p
                  style="margin: 10px 0; font-size: 14px; text-align: center"
                  v-html="resume.summary"
                ></p>
              </div>
            </div>
            <div
              v-if="resume.currentstep != 17 && !resume.summary"
              style="margin-top: 20px"
            >
              <div style="font-size: 18px; text-align: center">About Me</div>
              <div>
                <p
                  style="
                    margin: 10px 0;
                    font-size: 14px;
                    text-align: center;
                    color: grey;
                  "
                >
                  Motivated Sales Associate with 5 years of experience boosting
                  sales and customer loyalty through individualized service.
                  Resourceful expert at learning customer needs, directing to
                  desirable merchandise and upselling to meet sales quotas.
                  Committed to strengthening customer experiences with
                  positivity and professionalism when answering requests and
                  processing sales.
                </p>
              </div>
            </div>

            <div style="">
              <div style="padding: 20px 0">
                <div
                  v-if="resume.profile.address"
                  style="display: flex; margin-bottom: 15px"
                >
                  <span style="width: 24px; height: 24px; min-width: 24px"
                    ><img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/0097b2/home--v1.png"
                      alt="home--v1"
                  /></span>
                  <p style="margin: 0 0 0 10px">{{ resume.profile.address }}</p>
                </div>
                <div
                  v-if="resume.profile.phone"
                  style="display: flex; margin-bottom: 15px"
                >
                  <span style="width: 24px; height: 24px; min-width: 24px"
                    ><img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/0097b2/phone-disconnected.png"
                      alt="phone-disconnected"
                  /></span>
                  <p style="margin: 0 0 0 10px">{{ resume.profile.phone }}</p>
                </div>
                <div
                  v-if="resume.profile.twitter"
                  style="display: flex; margin-bottom: 15px"
                >
                  <span style="width: 24px; height: 24px; min-width: 24px">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/glyph-neue/36/5B94DE/twitter--v1.png"
                      alt="globe--v1"
                  />
                </span>
                  <p style="margin: 0 0 0 10px">{{ resume.profile.twitter }}</p>
                </div>
                <div
                  v-if="resume.profile.linkedin"
                  style="display: flex; margin-bottom: 15px"
                >
                  <span style="width: 24px; height: 24px; min-width: 24px">
                    <img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/color/36/linkedin.png"
                      alt="globe--v1"
                  /></span>
                  <p style="margin: 0 0 0 10px">
                    {{ resume.profile.linkedin }}
                  </p>
                </div>
                <div
                  v-if="resume.profile.website"
                  style="display: flex; margin-bottom: 15px"
                >
                  <span style="width: 24px; height: 24px; min-width: 24px"
                    ><img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/glyph-neue/24/0097b2/globe--v1.png"
                      alt="globe--v1"
                  /></span>
                  <p style="margin: 0 0 0 10px">{{ resume.profile.website }}</p>
                </div>

                <div
                  v-if="resume.profile.email"
                  style="display: flex; margin-bottom: 15px"
                >
                  <span style="width: 24px; height: 24px; min-width: 24px"
                    ><img
                      width="18"
                      height="18"
                      src="https://img.icons8.com/ios/24/0097b2/new-post--v1.png"
                      alt="new-post--v1"
                  /></span>
                  <p style="margin: 0 0 0 10px">{{ resume.profile.email }}</p>
                </div>
              </div>
            </div>

            <div v-if="resume.skills && resume.skills.length > 0" style="">
              <div
                :style="{ background: resume.profile.color }"
                style="color: #fff; padding: 10px 30px; font-size: 18px"
              >
                Expertise
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li
                    v-for="(item, index) in resume.skills"
                    style="margin-bottom: 8px"
                  >
                    {{ item.text }}
                    <div style="display: block">
                      <span
                        v-for="(data, index) in item.rate"
                        style="
                          width: 10px;
                          height: 10px;
                          border-radius: 50%;
                          background: #999;
                          display: inline-block;
                          margin-right: 4px;
                        "
                      ></span>
                      <span
                        v-if="5 - item.rate != 5"
                        v-for="(data, index) in 5 - item.rate"
                        style="
                          width: 10px;
                          height: 10px;
                          border-radius: 50%;
                          background: #ccc;
                          display: inline-block;
                          margin-right: 4px;
                        "
                      ></span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div
              v-if="
                resume.currentstep != 17 &&
                !(resume.skills && resume.skills.length > 0)
              "
              style=""
            >
              <div
                :style="{ background: resume.profile.color }"
                style="color: #fff; padding: 10px 30px; font-size: 18px"
              >
                Expertise
              </div>
              <div style="padding: 20px 30px">
                <ul
                  style="list-style: none; padding: 0; margin: 0; color: grey"
                >
                  <li style="margin-bottom: 8px">Accurate money handling</li>
                  <li style="margin-bottom: 8px">Sales Enterprising</li>
                  <li style="margin-bottom: 8px">Store opening and closing</li>
                </ul>
              </div>
            </div>
            <div
              v-if="resume.extraFields.accomplishments_text != 'None'"
              style=""
            >
              <div
                :style="{ background: resume.profile.color }"
                style="color: #fff; padding: 10px 30px; font-size: 18px"
              >
                Accomplishment
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li
                    v-html="resume.extraFields.accomplishments_text"
                    style="margin-bottom: 8px"
                  ></li>
                </ul>
              </div>
            </div>
            <div v-if="resume.extraFields.affiliations_text != 'None'" style="">
              <div
                :style="{ background: resume.profile.color }"
                style="color: #fff; padding: 10px 30px; font-size: 18px"
              >
                Affiliation
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li
                    v-html="resume.extraFields.affiliations_text"
                    style="margin-bottom: 8px"
                  ></li>
                </ul>
              </div>
            </div>
            <div v-if="resume.extraFields.software_text != 'None'" style="">
              <div
                :style="{ background: resume.profile.color }"
                style="color: #fff; padding: 10px 30px; font-size: 18px"
              >
                Software
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li
                    v-html="resume.extraFields.software_text"
                    style="margin-bottom: 8px"
                  ></li>
                </ul>
              </div>
            </div>
            <div v-if="resume.extraFields.languages_text != 'None'" style="">
              <div
                style="
                  background: #0097b2;
                  color: #fff;
                  padding: 10px 30px;
                  font-size: 18px;
                "
              >
                Languages
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li
                    v-html="resume.extraFields.languages_text"
                    style="margin-bottom: 8px"
                  ></li>
                </ul>
              </div>
            </div>
            <div
              v-if="resume.extraFields.certifications_text != 'None'"
              style=""
            >
              <div
                :style="{ background: resume.profile.color }"
                style="padding: 10px 30px; font-size: 18px"
              >
                Certification
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li
                    v-html="resume.extraFields.certifications_text"
                    style="margin-bottom: 8px"
                  ></li>
                </ul>
              </div>
            </div>
            <div v-if="resume.extraFields.interests_text != 'None'" style="">
              <div
                style="
                  background: #0097b2;
                  color: #fff;
                  padding: 10px 30px;
                  font-size: 18px;
                "
              >
                Interest
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li
                    v-html="resume.extraFields.interests_text"
                    style="margin-bottom: 8px"
                  ></li>
                </ul>
              </div>
            </div>
            <div
              v-if="resume.extraFields.add_your_own_json.length > 0"
              v-for="(data, index) in resume.extraFields.add_your_own_json"
              style=""
            >
              <div
                :style="{ background: resume.profile.color }"
                style="padding: 10px 30px; font-size: 18px"
              >
                {{ data.title }}
              </div>
              <div style="padding: 20px 30px">
                <ul style="list-style: none; padding: 0; margin: 0">
                  <li v-html="data.body" style="margin-bottom: 8px"></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="right-container" style="width: 70%; padding: 30px">
            <div style="text-align: left">
              <h1
                :style="{ color: resume.profile.color }"
                style="
                  display: block;
                  text-transform: uppercase;
                  font-size: 36px;
                  font-weight: 700;
                  margin: 0;
                "
                v-if="resume.profile.firstname"
              >
                {{ resume.profile.firstname + " " + resume.profile.surname }}
              </h1>
              <h1
                :style="{ color: resume.profile.color }"
                style="
                  display: block;
                  text-transform: uppercase;
                  font-size: 36px;
                  font-weight: 700;
                  margin: 0;
                "
                v-if="resume.currentstep != 17 && !resume.profile.firstname"
              >
                Suresh Raina
              </h1>
              <span
                style="
                  display: block;
                  text-transform: uppercase;
                  font-weight: 500;
                  letter-spacing: 1px;
                  margin-bottom: 30px;
                "
                >{{ resume.profile.profession }}</span
              >
            </div>

            <div style="margin-bottom: 30px">
              <div v-if="resume.education && resume.education.length > 0">
                <div
                  :style="{ background: resume.profile.color }"
                  style="color: #fff; padding: 10px 20px; font-size: 18px"
                >
                  Education
                </div>
                <div
                  v-for="(item, index) in resume.education"
                  style="display: flex; margin: 20px 0"
                >
                  <div style="padding-left: 0">
                    <h4
                      style="margin: 0 0 5px; font-size: 18px; font-weight: 500"
                    >
                      {{ item.school_name }}
                    </h4>
                    <h6
                      style="
                        margin: 0 0 5px;
                        font-size: 15px;
                        font-weight: 500;
                        font-style: italic;
                      "
                    >
                      {{ item.degree + " " + item.field }}
                    </h6>
                    <h5
                      style="margin: 0 0 5px; font-size: 15px; font-weight: 500"
                    >
                      {{ item.grad_startdate }} - {{ item.grad_enddate }}
                    </h5>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  resume.currentstep != 17 &&
                  !(resume.education && resume.education.length > 0)
                "
              >
                <div
                  :style="{ background: resume.profile.color }"
                  style="color: #fff; padding: 10px 20px; font-size: 18px"
                >
                  Education
                </div>
                <div style="display: flex; margin: 20px 0; color: grey">
                  <div style="padding-left: 0">
                    <h4
                      style="margin: 0 0 5px; font-size: 18px; font-weight: 500"
                    >
                      Oxford University
                    </h4>
                    <h6
                      style="
                        margin: 0 0 5px;
                        font-size: 15px;
                        font-weight: 500;
                        font-style: italic;
                      "
                    >
                      Msc Computer Science
                    </h6>
                    <h5
                      style="margin: 0 0 5px; font-size: 15px; font-weight: 500"
                    >
                      01/2013 - 03/2016
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <div style="margin-bottom: 30px">
              <div
                v-if="resume.workExperience && resume.workExperience.length > 0"
              >
                <div
                  :style="{ background: resume.profile.color }"
                  style="color: #fff; padding: 10px 20px; font-size: 18px"
                >
                  Work Experience
                </div>
                <div
                  v-for="(item, index) in resume.workExperience"
                  style="display: flex; margin: 20px 0"
                >
                  <div style="padding-left: 0">
                    <h4
                      style="margin: 0 0 5px; font-size: 18px; font-weight: 500"
                    >
                      {{ item.employer }}
                    </h4>
                    <h6
                      style="margin: 0 0 5px; font-size: 14px; font-weight: 500"
                    >
                      {{ item.jobtitle }}
                    </h6>
                    <h5
                      style="margin: 0 0 5px; font-size: 15px; font-weight: 500"
                    >
                      {{ item.startdate }} - {{ item.enddate }}
                    </h5>
                    <ul style="list-style: none; margin: 0; padding: 0 0 0 0">
                      <li
                        v-html="item.description"
                        style="font-size: 14px; margin-top: 10px"
                      ></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                v-if="
                  resume.currentstep != 17 &&
                  !(resume.workExperience && resume.workExperience.length > 0)
                "
              >
                <div
                  :style="{ background: resume.profile.color }"
                  style="color: #fff; padding: 10px 20px; font-size: 18px"
                >
                  Work Experience
                </div>
                <div style="display: flex; margin: 20px 0; color: grey">
                  <div style="padding-left: 0">
                    <h4
                      style="margin: 0 0 5px; font-size: 18px; font-weight: 500"
                    >
                      Compubotics Banglore India
                    </h4>
                    <h6
                      style="margin: 0 0 5px; font-size: 14px; font-weight: 500"
                    >
                      Software Engineer
                    </h6>
                    <h5
                      style="margin: 0 0 5px; font-size: 15px; font-weight: 500"
                    >
                      07/2017 - Current
                    </h5>
                    <ul style="list-style: none; margin: 0; padding: 0 0 0 0">
                      <li style="font-size: 14px; margin-top: 10px">
                        Effectively upsold products by introducing accessories
                        and other add-ons, adding ₹3000 to average monthly
                        sales.
                      </li>
                    </ul>
                  </div>
                </div>
                <div style="display: flex; margin: 20px 0; color: grey">
                  <div style="padding-left: 0">
                    <h4
                      style="margin: 0 0 5px; font-size: 18px; font-weight: 500"
                    >
                      Reality Great Company
                    </h4>
                    <h6
                      style="margin: 0 0 5px; font-size: 14px; font-weight: 500"
                    >
                      Senior Graphics Designer
                    </h6>
                    <h5
                      style="margin: 0 0 5px; font-size: 15px; font-weight: 500"
                    >
                      2018 - 2020
                    </h5>
                    <ul style="list-style: none; margin: 0; padding: 0 0 0 0">
                      <li style="font-size: 14px; margin-top: 10px">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec quis velit scelerisque, lobortis libero vitae,
                        efficitur quam. Aenean eu varius lacus.
                      </li>
                      <li style="font-size: 14px; margin-top: 10px">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec quis velit scelerisque, lobortis libero vitae,
                        efficitur quam. Aenean eu varius lacus.
                      </li>
                    </ul>
                  </div>
                </div>
                <div style="display: flex; margin: 20px 0; color: grey">
                  <div style="padding-left: 0">
                    <h4
                      style="margin: 0 0 5px; font-size: 18px; font-weight: 500"
                    >
                      Reality Great Company
                    </h4>
                    <h6
                      style="margin: 0 0 5px; font-size: 14px; font-weight: 500"
                    >
                      Senior Graphics Designer
                    </h6>
                    <h5
                      style="margin: 0 0 5px; font-size: 15px; font-weight: 500"
                    >
                      2018 - 2020
                    </h5>
                    <ul style="list-style: none; margin: 0; padding: 0 0 0 0">
                      <li style="font-size: 14px; margin-top: 10px">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec quis velit scelerisque, lobortis libero vitae,
                        efficitur quam. Aenean eu varius lacus.
                      </li>
                      <li style="font-size: 14px; margin-top: 10px">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Donec quis velit scelerisque, lobortis libero vitae,
                        efficitur quam. Aenean eu varius lacus.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
    <div v-if="loader">
      <div
        class="processing_popup upload full-spinner"
        style="visibility: visible"
      >
        <div class="spinner">
          <div class="bounce1"></div>
          <div class="bounce2"></div>
          <div class="bounce3"></div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <Messages
        v-if="isdisplaymsg"
        :datamessage="sucessmsgtext"
        v-on:closemsg="closeMessage"
        v-bind:isVisible="isdisplaymsg"
      ></Messages>
    </transition>
  </div>
</template>

<script>
import html2pdf from "html2pdf.js";
import AWS from "aws-sdk";
import axios from "axios";
import rest_service from "@/api/rest_service_api.js";
import Messages from "@/components/MessagePopup/Messages";
export default {
  name: "Resumeone",
  components: { Messages },
  props: ["resume", "showlist", "parentObj", "final", "from"],
  data() {
    return {
      showmodal: false,
      isdisplaymsg: false,
      sucessmsgtext: "",
      loader: false,
    };
  },
  computed: {
    showClosePopUp() {
      var self = this;
      self.showmodal = self.showlist;
    },
  },
  methods: {
    closePopUp: function (item) {
      if (item === "goBack") {
        if (this.parentObj.$data.showResumetwo) {
          this.parentObj.$data.showResumetwo = false;
        }
      }
    },
    navigate() {
      if (this.from == "create") {
        this.$router.push({
          path: "/CV/" + this.resume.UniqueId,
        });
      } else {
        this.$router.push({
          path: "/dashboard",
        });
      }
    },
    closeMessage() {
      this.isdisplaymsg = false;
      // this.iserrormsg = false;
    },
    async saveResume() {
      debugger;
      var self = this;
      var uid;
      if (localStorage.getItem("loggedinuserobj")) {
        uid = JSON.parse(localStorage.getItem("loggedinuserobj"));
      }
      const pdfContent =
        "<!DOCTYPE html><html><head><meta http-equiv='Content-Type' content='text/html; charset=utf-8'/></head><body>" +
        document.getElementById("resumesec").outerHTML +
        "</body></html>";
      //const html = JSON.stringify(pdfContent);
      var file = {
        content: pdfContent,
        UserEmail: uid.user.email,
        UniqueId: self.resume.UniqueId,
        Profile: JSON.stringify(self.resume.profile),
        WorkExperience: JSON.stringify(self.resume.workExperience),
        Education: JSON.stringify(self.resume.education),
        Skills: JSON.stringify(self.resume.skills),
        Summary: self.resume.summary,
        Color: self.resume.color,
        ExtraFields: JSON.stringify(self.resume.extraFields),
        Purchased_Package_id: self.resume.Purchased_Package_id,
        Profile_pic: self.resume.Profile_pic,
        TimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        cvfilename:
          self.resume.profile.firstname +
          "_" +
          self.resume.profile.surname +
          "_",
        Template: "resume12.png",
      };
      var Url = "";
      if (self.from == "create") {
        Url = "/S3proxy/SavenewCV";
      } else {
        Url = "/S3proxy/SavetoS3";
      }
      self.loader = true;
      // let formData = new FormData();
      // const pdfBlob = await html2pdf().from(pdfContent).outputPdf();
      // const blobPartArray = await new Response(pdfBlob).arrayBuffer(); // Convert Blob to BlobPart array

      // const formData = new FormData();
      // formData.append('file', new Blob([blobPartArray]), 'output.pdf');

      // formData.append('pdfFile', pdfBlob, 'output.pdf');
      axios({
        method: "post",
        url: rest_service.baseURL + Url,
        // url: "https://localhost:7101/api" + Url,
        data: file,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + uid.returnedToken,
        },
      })
        .then(function (response) {
          if (response) {
            self.loader = false;
            //self.closePopUp("goBack");
            self.sucessmsgtext = "CV saved successfully";
            self.isdisplaymsg = true;
            setTimeout((xy) => {
              self.closeMessage();
            }, 5000);
            setTimeout((y) => {
              self.navigate();
            }, 1000);
            debugger;
          } else {
            return null;
          }
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
      //const pdfBuffer =  pdfBlob.arrayBuffer();
      //   const s3 = new AWS.S3({
      //   accessKeyId: 'AKIA5BLF7Z4HNIYBATMF',
      //   secretAccessKey: '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',
      //   region: 'us-east-1',
      // });

      // // Define S3 upload parameters
      // const params = {
      //   Bucket: 'documents-gen-ehr-dev',
      //   Key: 'shri@mailinator.com/document.pdf', // Desired name for the uploaded file
      //   Body: pdfBlob,
      //   ContentType: 'application/pdf',
      //   ACL: 'private',
      // };

      // // Upload to S3
      // s3.upload(params, (error, data) => {
      //   if (error) {
      //     console.error('Error uploading to S3:', error);
      //   } else {
      //     console.log('PDF uploaded to S3:', data.Location);
      //   }
      // });
      //  html2pdf(document.getElementById("resumesec"), {
      // 		margin: 1,
      // 	filename: "i-was-html.pdf",
      // 	});
    },
  },
};
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease-in-out;
}
/* .modal-wrapper {
  display: table-cell;
  vertical-align: middle;
} */
.modal-container {
  width: 500px;
  margin: 0px auto;
  padding: 20px 50px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: opacity 0.5s ease-in-out;
}
.modal-mask.upl-holdNEW.wdth-700 .modal-container {
  width: 700px;
}

.modal-mask:before {
  display: inline-block;
  vertical-align: middle;
  content: " ";
  height: 100%;
}

.modal-wrapper {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  width: 100%;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->

